.pre-footer-cta {
  position: relative;
  overflow: hidden;

  background-color: black;

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      
      width: 100%;
      height: 100%;

      background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      opacity: 0.5;
      transition: all 0.5s ease-out;
    }

    .inner {
      position: relative;
      z-index: 3;
    }

}

.pre-footer-cta__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  opacity: 1;
  transition: all 0.5s ease-out;

    @include mq(screen-xs) {
      width: 100%;
      height: auto;
    }

}

.pre-footer-cta__content {
  display: grid;
  place-items: center;
  gap: var(--padding-lg);
  padding: clamp(5rem, 2.5000rem + 12.5000vw, 12.5000rem) 0;
}

.pre-footer-cta__title {
  font-family: var(--loos-extended);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  text-align: center;
  color: white;
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);

    i {
      font-family: var(--loos-compressed);
      font-style: normal;
    }

}

.pre-footer-cta__text {
  text-align: center;
  color: white;
}

.single-product {

  .pre-footer-cta__title {
    color: var(--color-dark-peak);
  }
  
  .pre-footer-cta__text {
    color: var(--color-dark-peak);
  }

  .pre-footer-cta {
    background-color: white;

      &:after {
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      }

  }

}

// Form

.email-signup {
  width: 100%;
  padding: var(--padding-lg) 0 0;

  border-top: 1px solid rgba(white, 0.25);
}

.email-signup form {
  display: grid;
  gap: var(--padding-md);
}

.email-signup__row {
  display: flex;
  justify-content: center;

    p {    
      text-align: center;
      font-size: var(--font-size-xxs);
      line-height: var(--line-height-md);
      color: white;
    }
}

.email-signup__fields {
  display: grid;
  gap: var(--padding-lg);
  width: 100%;

    @include mq(screen-sm) {
      grid-template-columns: repeat(3, 1fr);
    }

}

.email-signup__field {
  flex: 1;
  display: grid;
  gap: var(--padding-sm);

    label {
      font-family: var(--loos-condensed);
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      line-height: var(--loos-line-height);
      letter-spacing: var(--loos-kerning);
      color: white;
    }

    input[type="text"],
    input[type="email"] {
      width: 100%;
      height: pxRem(60);
      padding: 0 pxRem(10);

      font-family: inherit;

      border: none;

        &:focus {
          outline: none;
          border: 3px solid var(--color-blue-john);
        }

    }

}

.email-signup__validation {
  display: block;
  width: 100%;
  padding: 1rem;

  font-weight: var(--font-weight-bold);
  color: var(--color-grey-dark);
}

.email-signup__validation--error {
  margin: var(--padding-lg) 0 0;

  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.email-signup__validation--success {
  margin: var(--padding-lg) 0 0;
  
  border: 1px solid #80A06E;
  background-color: #EDF1EA;
}

// Animations

.pre-footer-cta.is-visible {

  &:after {
    opacity: 0.5;
    transition: all 0.5s ease-in;
  }
  
  .pre-footer-cta__img {
    opacity: 0.5;
    transition: all 0.5s ease-in;
  }

}