.text-img-inner.bg-dark-peak {
  padding: var(--padding-xl) 0;
  background-color: var(--color-dark-peak);

    p {
      color: white;
    }

}

.text-img {
  display: grid;
  gap: var(--padding-xxl);
}

.text-img__img-col__img {
  display: block;
  width: 100%;
  height: auto;
}

// Variants

.text-img-outer.is-odd {

  .text-img {

    @include mq(screen-xs) {
      grid-template-columns: 2fr 1fr;
    }

    @include mq(screen-lg) {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  
  }

}

.text-img-outer.is-even {

  .text-img {

    @include mq(screen-xs) {
      grid-template-columns: 1fr 2fr;
    }

    @include mq(screen-lg) {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  
  }
  
  .text-img__text-col {
    order: 2;
  }

  .text-img__img-col {
    order: 1;
  }

}