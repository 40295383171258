.page-template-tpl-my-account .woocommerce {
  margin: 0 0 var(--padding-xl);
}

.woocommerce-form-login,
.woocommerce-form-register,
.edit-account,
.edit-account fieldset,
.woocommerce-address-fields,
.woocommerce-address-fields__field-wrapper {
  display: grid;
  gap: var(--padding-md);
}

.woocommerce-register {
  margin: var(--padding-xl) 0 0;
  padding: var(--padding-xl) 0 0;

  border-top: 1px solid var(--color-grey-light);
}

.woocommerce-form-row,
.woocommerce .form-row {
  display: grid;
  gap: var(--padding-sm);

  label {
    display: block;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    text-align: left;
  }
}

.woocommerce-form__label-for-checkbox {
  display: flex;
  gap: var(--padding-xs);

  cursor: pointer;
}

.edit-account fieldset {
  display: grid;
  gap: var(--padding-md);
  padding: var(--padding-md) 0 0;
  
  border-top: 3px solid var(--color-grey-light);
}

.select2-container--default .select2-selection--single {
  display: flex;
  align-items: center;
  height: var(--btn-height);
	padding: 0 var(--padding-xs);
	border: 1px solid var(--color-dark-peak);
  border-radius: 0;

		&:focus {
			outline: none;
			border: 3px solid var(--color-blue-john);
		}

}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%);
}

.woocommerce-MyAccount-navigation {
  margin: 0 0 var(--padding-lg);

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding-md) var(--padding-lg);
  }
  
  a {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  text-decoration: none;
  line-height: 1;

    &:hover {
      color: var(--color-midnight-black);
      text-decoration: underline;
    }

  }

}

.woocommerce-Addresses {
  display: grid;
  gap: var(--padding-lg);
}

.woocommerce-MyAccount-orders.shop_table {

  th, td {
    padding: 0 var(--padding-sm) var(--padding-sm) 0;
  }
  th {
    font-weight: var(--font-weight-bold);
  }

  a {
    color: inherit;
  }

}

.woocommerce-info a {
  color: inherit;
}

.woocommerce-MyAccount-content {
  display: grid;
  gap: var(--padding-lg);
}

.woocommerce-Address {
  display: grid;
  gap: var(--padding-md);

    address {
      font-style: normal;
    }
    
}

.woocommerce-privacy-policy-text {
  a {
    color: inherit;
  }

  a:hover {
    opacity: 0.5;
  }
}