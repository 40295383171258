.news-feed {
  display: grid;
  gap: var(--padding-xl);
  margin: 0 0 var(--padding-xxl);

    @include mq(screen-xxs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-md) {
      grid-template-columns: repeat(3, 1fr);
      gap: var(--padding-xl) var(--padding-lg);
    }

}

.news-feed__img-outer {
  display: block;
  position: relative;
  aspect-ratio: 16/9;
}

.news-feed__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-feed__text {
  display: grid;
  gap: var(--padding-sm);
  padding: var(--padding-md) var(--padding-lg) var(--padding-md) 0;
}