.basket {
  display: grid;
  gap: var(--padding-xl);
  margin: 0 0 var(--padding-xl);

    @include mq(screen-sm) {
      grid-template-columns: 2fr 1fr;
    }

}

.product-table {
  display: grid;
  gap: var(--padding-md);

    .cart_item {
      position: relative;
      min-height: pxRem(80);
      padding: 0 pxRem(50) 0 pxRem(80);

        @include mq(screen-md) {
          display: flex;
          padding: 0;
        }

    }

    .product-thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      display: grid;
      place-items: center;
      width: pxRem(80);
      height: pxRem(80);

        @include mq(screen-md) {
          position: relative;
        }

    }

    .product-thumbnail img {
      display: block;
      width: auto;
      height: pxRem(80);
    }

    .group-1 {
      margin: 0 0 var(--padding-sm);

        @include mq(screen-md) {
          display: flex;
          align-items: center;
          margin: 0;
          flex: 1;
        }
    }

    .group-2 {
      display: flex;
      gap: var(--padding-md);
      // justify-content: space-between;
      align-items: center;
    }

    .product-name {
      flex: 1;
    }

    .product-price {
      width: pxRem(150);

        span {
          font-family: var(--loos-compressed);
          letter-spacing: var(--loos-kerning);
          text-transform: uppercase;
          color: var(--color-dark-peak);
        }

    }

    .product-remove {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      transform: translateY(-50%);

      display: grid;
      place-items: center;
      width: pxRem(40);
      height: pxRem(40);

        @include mq(screen-md) {
          position: relative;
          top: 0;
          transform: none;
        }

        &:hover {
          background-color: var(--color-light-peak);
        }

    }

    .product-remove svg {
      width: pxRem(16);
      height: pxRem(16);

      fill: var(--color-dark-peak);
    }

}

.cart-text {
  display: grid;
  gap: var(--padding-xs);
}

.cart-collaterals {
  padding: var(--padding-lg);
  background-color: var(--color-light-peak);
}

.cart_totals {
  display: grid;
  gap: var(--padding-lg);
}

.price-table {

  .shop_table {

    th {
      width: 40%;
      padding: 0 0 var(--padding-sm);
      vertical-align: top;
    }

    td {
      width: 60%;
      padding: 0 0 var(--padding-sm) var(--padding-sm);
      vertical-align: top;
    }

    tr:last-child {
      
      th {
        padding: 0;
      }

      td {
        padding: 0 0 0 var(--padding-sm);
      }

    }

  }

}

.woocommerce-table--order-details.shop_table {
  td {
    width: auto;
    padding: 0 0 var(--padding-sm);
  }
}

.cart-subtotal th,
.cart-discount th,
.shipping th {
  font-weight: var(--font-weight-bold);
}

.cart-subtotal td,
.order-total td,
.cart-discount td,
.woocommerce-shipping-methods {
  font-family: var(--loos-compressed);
  letter-spacing: var(--loos-kerning);
  color: var(--color-dark-peak);
}

.cart-discount td a {
  padding: 0 0 0 pxRem(10);
  color: var(--color-dark-peak);
}

.shipping th,
.shipping td {
  vertical-align: top;
}

.woocommerce-shipping-methods {
  margin: 0 0 var(--padding-sm);
    
    input[type="radio"] {
      padding: 0;
      margin: 0 var(--padding-xs) 0 0;
    }

}

.order-total {
  font-size: var(--font-size-md);
}

.order-total th {
  @extend .title;
  @extend .title--md;
}

.order-total td {
  color: var(--color-dark-peak);
}

.wc-proceed-to-checkout {
  display: grid;
  gap: var(--padding-md);
  padding: var(--padding-lg) 0 0;

  border-top: 1px solid var(--color-grey);
}

.ssl-label {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);
  
    svg {
      width: pxRem(16);
      height: pxRem(16);

      fill: var(--color-blue-john);
    }

}

.actions {
  display: grid;
  gap: var(--padding-lg);
  padding: var(--padding-lg) 0 0;

  border-top: 1px solid var(--color-grey-light);

    @include mq(screen-sm) {
      gap: var(--padding-xxl);
      grid-template-columns: 2fr 1fr;
    }

}

.coupon,
.update-basket {
  display: grid;
  gap: var(--padding-sm);
}

.coupon-form {
  display: flex;
  gap: var(--padding-md);
  width: 100%;

    input[type="text"] {
      flex: 1;
      text-align: left;
    }

}

// Basket empty

.wc-empty-cart-message {
  margin: 0 0 var(--padding-lg);

  text-align: center;
}

.woocommerce-cart .cart-empty {
  @extend .title;
  @extend .title--sm;
}

.return-to-shop {
  display: grid;
  justify-content: center;
}