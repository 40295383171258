.contact-form-outer {
  position: relative;
  padding: var(--padding-lg) 0 0;
  margin: 0 0 var(--padding-lg);

  background-color: var(--color-light-peak);
  
    > .inner {
      position: relative;
      z-index: 2;
    }

}

.contact-form__text {
  display: grid;
  gap: var(--padding-lg);
}

.contact-form__compass {
  display: none;

    @include mq(screen-sm) {
      display: block;
      width: auto;
      height: pxRem(200);
      transform: rotate(10deg);
    }

}

.contact-form {
  display: grid;
  gap: var(--padding-xl);
  align-items: start;
  padding: var(--padding-lg) 0;

    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-lg) {
      grid-template-columns: 1fr 1.5fr;
      gap: var(--padding-xxl);
    }

}

.contact-form__row + .contact-form__row {
  margin: var(--padding-md) 0 0;
}

.contact-form__row {
  display: grid;
  gap: var(--padding-sm);

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="url"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="week"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  select {
    width: 100%;
    height: pxRem(60);
  }

  select {
    padding: 0 var(--padding-xs);
    border: 1px solid var(--color-dark-peak);

    font-family: var(--loos-normal);
    font-size: var(--font-size-xs);

      &:focus {
        outline: none;
        border: 3px solid var(--color-blue-john);
      }

  }

  textarea {
    width: 100%;
    height: pxRem(150);
    padding: var(--padding-xs);
    border: 1px solid var(--color-dark-peak);

    font-family: var(--loos-normal);
    font-size: var(--font-size-xs);

      &:focus {
        outline: none;
        border: 3px solid var(--color-blue-john);
      }

  }

  > label {
    font-family: var(--loos-condensed);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    line-height: var(--loos-line-height);
    letter-spacing: var(--loos-kerning);
  }


}

// GDPR

.wpcf7-acceptance,
.wpcf7-checkbox {
  display: block;
  width: 100%;
  padding: var(--padding-xs);
}

.wpcf7-acceptance input, 
.wpcf7-checkbox input {
  margin: 0 1rem 0 0;
}

.wpcf7-acceptance .wpcf7-list-item-label {
  line-height: var(--line-height-md);

    a {
      color: inherit;
    }
    
}

// Validation

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid-tip {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  margin: 0;

  font-weight: var(--font-weight-bold);

  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.wpcf7-response-output {
  display: block;
  width: 100%;
  padding: 1rem;

  font-weight: var(--font-weight-bold);
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;

  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;
  
  border: 1px solid #80A06E;
  background-color: #EDF1EA;
}


