/*!
Theme Name: Buxton Brewery
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/

@function pxRem($value) {
  $remValue: ($value / 16) + rem; 
  @return $remValue;
}

:root {  

  // Font sizes

  --font-size-xxl: clamp(2.25rem, 1.5000rem + 3.7500vw, 4.5000rem); /* 36px @ 320px > 72px @ 1280px */
  --font-size-xl: clamp(2rem, 1.6667rem + 1.6667vw, 3.0000rem); /* 32px @ 320px > 48px @ 1280px */
  --font-size-lg: clamp(1.8rem, 1.6500rem + 0.7500vw, 2.2500rem); /* 28.8px @ 320px > 36px @ 1280px */
  --font-size-md: clamp(1.35rem, 1.2375rem + 0.5625vw, 1.6875rem); /* 21.6px @ 320px > 27px @ 1280px */
  --font-size-sm: clamp(1.05rem, 0.9625rem + 0.4375vw, 1.3125rem); /* 16.8px @ 320px > 21px @ 1280px */
  --font-size-xs: clamp(0.9rem, 0.8250rem + 0.3750vw, 1.1250rem); /* 14.4px @ 320px > 18px @ 1280px */
  --font-size-xxs: 1rem;

  // Font families

  --gravesend-sans: gravesend-sans, sans-serif;
  --loos-compressed: loos-compressed, sans-serif;
  --loos-normal: loos-normal, sans-serif;
  --loos-extended: loos-extended, sans-serif;

  // Type formatting

  --loos-medium: 500;
  --font-weight-bold: 700;
  --line-height-sm: 1.33;
  --line-height-md: 1.66;
  --line-height-lg: 2;
  --loos-kerning: -0.05rem;
  --loos-line-height: 0.8;

  // Spacing

  --padding-xxl: clamp(3.125rem, 1.6667rem + 7.2917vw, 7.5000rem); /* 50px @ 320px > 120px @ 1280px */
  --padding-xl: clamp(2.4rem, 2.2000rem + 1.0000vw, 3.0000rem); /* 38.4px @ 320px > 48px @ 1280px */
  --padding-lg: clamp(1.6rem, 1.4667rem + 0.6667vw, 2.0000rem); /* 25.6px @ 320px > 32px @ 1280px */
  --padding-md: clamp(1rem, 0.9167rem + 0.4167vw, 1.2500rem); /* 16px @ 320px > 20px @ 1280px */
  --padding-sm: clamp(0.8rem, 0.7333rem + 0.3333vw, 1.0000rem); /* 12.8px @ 320px > 16px @ 1280px */
  --padding-xs: 0.5rem; // 8px
  --padding-xxs: 0.25rem; // 4px

  // Colors

  --color-grey: #8D8D8D;
  --color-grey-light: #EEE9E2;
  --color-light-peak: #f7f4f3;
  --color-midnight-black: #101d2e;
  --color-cavern-blue: #125564;
  --color-cavern-blue-hover: #104855;
  --color-dark-peak: #324142;
  --color-dark-peak-hover: #242F30;
  --color-blue-john: #0093D3;
  --color-blue-john-hover: #007DB3;
  --color-white-fade-10: rgba(255,255,255,0.1);
  --color-white-fade-50: rgba(255,255,255,0.5);
  --color-white-fade-80: rgba(255,255,255,0.8);
  --color-dark-peak-fade-80: rgba(50,65,66, 0.8);
  --color-dark-peak-fade-10: rgba(50,65,66, 0.1);
  --color-error: #C63636;

  // Media queries

  --mq-xxs: 450px;
  --mq-xs: 640px;
  --mq-sm: 768px;
  --mq-md: 1024px;
  --mq-lg: 1280px;
  --mq-xl: 1536px;
  --mq-xxl: 1800px;

  // Everything else

  --btn-height: 2.5rem;
  --paint-daub-padding: clamp(2.5rem, 1.0811rem + 7.0946vw, 9.0625rem);
  --paint-daub-max-height: clamp(1.875rem, -0.5208rem + 11.9792vw, 9.0625rem); /* 30px @ 320px > 145px @ 1280px */;

  // @media screen and (min-width: 1280px) {
  //   --btn-height: 2.75rem;
  // }

}

$color-profiles: (
  1: #fde294,
  2: #fbd375,
  3: #fbc35b,
  4: #f9b949,
  5: #f3a936,
  6: #f09e29,
  7: #e89427,
  8: #df8624,
  9: #dc7e23,
  10: #d27321,
  11: #ca691f,
  12: #c1621d,
  13: #bb5a1c,
  14: #b4531a,
  15: #aa4a18,
  16: #a44717,
  17: #9f4016,
  18: #953915,
  19: #8f3313,
  20: #893012,
  21: #832b11,
  22: #7e2911,
  23: #72240f,
  24: #711f0f,
  25: #6d1b0f,
  26: #651b0d,
  27: #5c190b,
  28: #5d130e,
  29: #55120a,
  30: #4a1009,
  31: #4a100b,
  32: #510f0b,
  33: #450f0e,
  34: #400c0c,
  35: #3a0c0c,
  36: #3a0e0e,
  37: #340e0d,
  38: #310c0d,
  39: #310d0f,
  40: #2e0d0e
);

@import "config/media-queries";

@import "global/reset";
@import "global/helpers";
@import "global/layout";
@import "global/spacing";
@import "global/header";
@import "global/footer";
@import "global/typography";
@import "global/btn";

@import "plugins/tiny-slider";
@import "plugins/woo";

@import "components/hero-slider";
@import "components/brand-strap";
@import "components/product-grid";
@import "components/promo-row";
@import "components/cta-grid";
@import "components/product";
@import "components/std-content";
@import "components/similar-beers";
@import "components/pre-footer-cta";
@import "components/collab-hero";
@import "components/promo-text";
@import "components/rich-text-col";
@import "components/text-img";
@import "components/shop-content";
@import "components/filters";
@import "components/text-hero";
@import "components/locations";
@import "components/basket";
@import "components/age-popup";
@import "components/news-feed";
@import "components/my-account";
@import "components/404";
@import "components/popup";
@import "components/hero-form";
@import "components/contact-form";
