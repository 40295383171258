.hero-slider-outer {
  position: relative;
}

.hero-slider {
  position: relative;
  z-index: 1;
}

.hero-slide,
.hero-banner {
  position: relative;
  z-index: 1;
  width: 100%;    
}

.hero-slide__img {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;

    @include mq(screen-lg) {
      height: pxRem(800);
      aspect-ratio: auto;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      display: block;
      width: auto;
      height: 100%;

        @include mq(screen-lg) {
          left: 0;
          transform: translateY(-50%);

          width: 100%;
          height: auto;
        }

    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      
      width: 100%;
      height: 100%;

      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      opacity: 0.95;
      transition: all 0.5s ease-out;
    }

}

.hero-banner__img {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;

    @include mq(screen-lg) {
      height: pxRem(600);
      aspect-ratio: auto;
    }

    img {
      position: relative;
      z-index: 1;

      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      
      width: 100%;
      height: 100%;

      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      opacity: 0.95;
      transition: all 0.5s ease-out;
    }

}

.hero-slide__text-outer,
.hero-banner__text-outer {
  position: absolute;
  bottom: pxRem(100);
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  width: 100%;
  padding: 0 var(--padding-lg);

    @include mq(screen-sm) {
      display: flex;
    }

    @include mq(screen-md) {
      bottom: pxRem(120);
    }

    @include mq(screen-lg) {
      width: var(--mq-lg);
    }

}

.hero-slide__text,
.hero-banner__text {
  display: grid;
  gap: var(--padding-md);
  
  @include mq(screen-md) {
    max-width: 50%;
  }

}

.hero-slider-nav {
  position: absolute;
  bottom: pxRem(30);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  display: flex;
  justify-content: center;
  gap: pxRem(5);
}

.hero-slider-nav__btn {
  display: block;
  width: pxRem(10);
  height: pxRem(10);

  background: rgba(255,255,255,0.15);;
  border: none;

    &:hover,
    &.tns-nav-active {
      background-color: rgba(255,255,255,0.3);
    }


}

// Variants

.hero-slide__text-outer {
  
  .hero-slide__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
  }

}

.hero-slide__text-outer--center {
  
  @include mq(screen-md) {
    justify-content: center;
  }

}

.hero-slide__text-outer--left {
  
  @include mq(screen-md) {
    justify-content: flex-start;
  }

  .hero-slide__text {
    
    @include mq(screen-md) {
      align-items: flex-start;

      text-align: left;
    }

  }

}

.hero-slide__text-outer--right {
  
  @include mq(screen-md) {
    justify-content: flex-end;
  }

  .hero-slide__text {
    
    @include mq(screen-md) {
      align-items: flex-start;

      text-align: left;
    }

  }

}

