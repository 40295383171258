.cta-grid {
  padding: 0 0 var(--padding-xxl);

  @include mq(screen-sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

}

.cta-grid__col:first-child {
  background-color: var(--color-white-fade-80);

    @include mq(screen-sm) {
      display: flex;
      flex-direction: column;
    }

}

.cta-grid__col:last-child {
  background-color: white;
}

.untapped-cta,
.trade-cta {
  display: grid;
  justify-items: center;
  align-items: center;
  gap: var(--padding-lg);

  text-align: center;
  text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

}

.untapped-cta {
  padding: var(--padding-xxl) var(--padding-xl);

  background-color: var(--color-dark-peak);
  color: white;
}

.trade-cta {
  padding: var(--padding-xl);

  color: var(--color-dark-peak);

    @include mq(screen-sm) {
      flex: 1;
      align-content: center;
    }
}

.untapped-cta__img,
.trade-cta__img {
  display: block;
  width: 100%;
  height: auto;
}

.untapped-cta__img {
  
  @include mq(screen-sm) {
    width: 50%;
  }

}

.untapped-cta__text,
.trade-cta__text {
  padding: 0 15%;

    b, strong {
      font-weight: var(--font-weight-bold);
    }
    
}

.map-cta__map {
  display: none;

    @include mq(screen-sm) {
      display: block;
    }

}

.map-cta__img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
}

.map-cta__cta {
  display: grid;
  justify-items: center;
  gap: var(--padding-lg);
  padding: var(--padding-xl);

    @include mq(screen-sm) {
      justify-items: start;
    }

    @include mq(screen-lg) {
      justify-items: start;
      grid-template-columns: 2fr 1fr;
    }

}

// Contact page

.ctas-outer {
  padding: var(--padding-xl) 0;
  
  background-color: var(--color-light-peak);
}

.ctas {
  display: grid;

    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    .trade-cta {
      background-color: white;
    }

}