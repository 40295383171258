.text-hero {
  padding: var(--paint-daub-max-height) 0 var(--padding-md);
  margin: 0 0 var(--padding-xl);

  border-bottom: 1px solid var(--color-grey-light);
}

.text-hero--contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);

  padding: var(--paint-daub-max-height) 0 0;

  border: none;
}

.text-hero--social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);
}

.text-hero__social {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-md);
}