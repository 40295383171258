.title {
  font-family: var(--gravesend-sans);
  line-height: 1;
  text-transform: uppercase;
}

.title--xxl {
  font-size: var(--font-size-xxl);
}

.title--xl {
  font-size: var(--font-size-xl);
}

.title--lg {
  font-size: var(--font-size-lg);
}

.title--md {
  font-size: var(--font-size-md);
}

.title--sm {
  font-size: var(--font-size-sm);
}

.text-white {
  color: white;
}

.text-error {
  color: var(--color-error);
}

a.title {
  color: inherit;
  text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    
}