.align-center {
  text-align: center;
}

.screen-reader-only {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.mobile-only {
  
  @include mq(screen-sm) {
    display: none;
  }

}

.bg-dark-peak {
  background-color: var(--color-dark-peak);
}

.bg-light-peak {
  background-color: var(--color-light-peak);
}

.bg-white {
  background-color: white;
}