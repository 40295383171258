// =================================================
// BREAKPOINT MEASUREMENTS
// =================================================

$screen-xxl: 1800px !default;
$screen-xl: 1536px !default;
$screen-lg: 1280px !default;
$screen-md: 1024px !default;
$screen-sm: 768px !default;
$screen-xs: 640px !default;
$screen-xxs: 450px !default;

@mixin mq($point) {

  @if $point == screen-xxl {
    @media (min-width: $screen-xxl) { @content; }
  }
  
  @if $point == screen-xl {
    @media (min-width: $screen-xl) { @content; }
  }

  @if $point == screen-lg {
    @media (min-width: $screen-lg) { @content; }
  }

  @if $point == screen-md {
    @media (min-width: $screen-md) { @content; }
  }

  @if $point == screen-sm {
    @media (min-width: $screen-sm) { @content; }
  }

  @if $point == screen-xs {
    @media (min-width: $screen-xs) { @content; }
  }

  @if $point == screen-xxs {
    @media (min-width: $screen-xxs) { @content; }
  }

}