// .filter-bar {
//   display: flex;
//   justify-content: space-between;
// }

// .filters {
//   display: flex;
// }

.shop-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 var(--padding-md);
  padding: 0 0 var(--padding-md);
  border-bottom: 1px solid var(--color-grey-light);
  
    @include mq(screen-lg) {
      flex-direction: column;
      align-items: start;
      gap: var(--padding-md);
    }

}

.filters-outer {
  display: none;
  z-index: 0;

  position: relative;
  top: 0;
  right: 0;
  z-index: 0;

  flex-direction: column;
  gap: var(--padding-md);

  width: 100%;
  height: 100%;
  padding: var(--padding-sm) 0 calc(var(--padding-xl) + var(--btn-height)) var(--padding-sm);
  overflow-y: scroll;

  background-color: white;

    &.is-open {
      display: flex;

      position: fixed;
      z-index: 10000;
    }

    @include mq(screen-sm) {
      width: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0,0.05), 0px 4px 8px rgba(0, 0, 0,0.05);
    }

    @include mq(screen-lg) {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      height: auto;
      padding: 0;
      overflow-y: visible;

      background: none;
      box-shadow: none;
    }

}

.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

    @include mq(screen-lg) {
      display: none;
    }
}

.filters-title {
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-sm);
  text-transform: uppercase;

    @include mq(screen-lg) {
      display: none;
    }

}

.filters-header__close {
  display: grid;
  place-items: center;
  width: var(--btn-height);
  height: var(--btn-height);

  background: none;

    @include mq(screen-lg) {
      display: none;
    }

    svg {
      width: pxRem(16);
      height: pxRem(16);

      fill: currentColor;
    }

}

.open-filters,
.toggle-sort {
  align-items: center;
  gap: var(--padding-sm);
  height: var(--btn-height);
  padding: 0 var(--padding-sm);

  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;

  border: 1px solid;
  color: var(--color-dark-peak);
  background: none;

    svg {
      width: pxRem(16);
      height: pxRem(16);

      fill: currentColor;
    }

}

.open-filters {
  display: flex;

    @include mq(screen-lg) {
      display: none;
    }

}

.toggle-sort {
  display: none;

    @include mq(screen-lg) {
      display: flex;
    }

    &:hover {
      background-color: var(--color-dark-peak-fade-10);
    }

    &.is-active {
      background-color: var(--color-dark-peak);
      color: white;
    }

}

.filters {
  
  @include mq(screen-lg) {
    display: flex;
    gap: var(--padding-md);
  }

}

.filter,
.sort-option {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);

  width: 100%;
  height: var(--btn-height);

  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  background: none;

    &:hover {
      color: var(--color-dark-peak-hover);
      text-decoration: underline;
    }

    &.mixitup-control-active {
      
      .filter__check {
        border-color: var(--color-dark-peak);
        background-color: var(--color-dark-peak);
      }

      .filter__check svg {
        display: block;
        fill: white;
      }

    }

}

.filter__check {
  display: grid;
  place-items: center;
  width: pxRem(20);
  height: pxRem(20);
  border: 1px solid var(--color-grey);

    svg {
      display: none;
      width: pxRem(12);
      height: pxRem(12);
    }

}

.sort {
  
  @include mq(screen-lg) {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;
    padding: var(--padding-md);

    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0,0.05), 0px 4px 8px rgba(0, 0, 0,0.05);
  }

  &.is-open {
    display: block;
  }

}

.filter-actions {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;

  width: 100%;
  padding: var(--padding-sm);
  background-color: var(--color-light-peak);

    @include mq(screen-sm) {
      width: 50%;
    }

    @include mq(screen-lg) {
      display: none;
    }

}

.filter-action {
  @extend .btn;
  @extend .btn--dark-peak;

  width: 100%;
}