.pg-footer-outer {
  position: relative;

  background-color: var(--color-dark-peak);
  overflow: hidden;

    .inner {
      z-index: 2;
    }

}

.pg-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-lg);
  padding: var(--padding-xl) 0;
}

.pg-footer__logo {
  width: 100%;
  padding: 0 0 var(--padding-lg);

  border-bottom: 1px solid var(--color-white-fade-10);
}

.pg-footer__logo svg {
  display: block;
  width: pxRem(150);
  margin: 0 auto;

  fill: white;

}

.pg-footer__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);

  @include mq(screen-xs) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--padding-md) var(--padding-lg);
  }

  li {
    text-align: center;
  }

  a {
    font-family: var(--loos-extended);
    font-size: var(--font-size-xxs);
    font-weight: var(--loos-medium);
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    line-height: 1;

      &:hover {
        text-decoration: underline;
      }

  }

}

.pg-footer-icons-outer {
  padding: var(--padding-xl) 0;

  background-color: white;
}

.pg-footer-icons {
  display: grid;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

}

.pg-footer-icons__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);

  text-align: center;
}

.social-list,
.payments-list {
  display: flex;
  flex-wrap: wrap;
  gap: pxRem(10);
}

.pg-footer-outer {
  
  .social-list,
  .payments-list {
    justify-content: center;
  }

}

.payments-list__icon {
  display: grid;
  place-items: center;
  width: pxRem(50);
  height: pxRem(40);

    img,
    svg {
      display: block;
      width: 100%;
      height: auto;
    }

}

.social-list__link {
  display: grid;
  place-items: center;
  width: pxRem(40);
  height: pxRem(40);

    img,
    svg {
      display: block;
      width: 100%;
      height: auto;
    }

}

.copyright {
  padding: var(--padding-lg);

  text-align: center;

  background-color: var(--color-light-peak);
}