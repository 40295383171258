.brand-strap {
  padding: var(--padding-lg);

    p {
      font-family: var(--loos-extended);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-xl);
      text-align: center;
      text-transform: uppercase;
      line-height: var(--loos-line-height);
      letter-spacing: var(--loos-kerning);
    }

    i {
      font-family: var(--loos-compressed);
      font-style: normal;
    }

}

.brand-strap.bg-dark-peak {
  background-color: var(--color-dark-peak);

    p {
      color: white;
    }

}