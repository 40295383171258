// =================================================
// STANDARD CONTENT STYLES
// =================================================


.std-content {
	width: 100%;

  & > *:not(h2, h3, h4, h5, h6)+* {
		margin: var(--padding-md) 0 0;
	}
	
	// Main headings

	h2:not(:first-child), h3:not(:first-child), h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
		margin: var(--padding-lg) 0 var(--padding-md);
	}

  h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
		margin: 0 0 var(--padding-md);
	}
	
	// Main headings
	
  h2 {
		@extend .title;
		@extend .title--md;
  }

  h3 {
		//color: var(--color-blue-dark);
		@extend .title;
		@extend .title--sm;
  }

  h4 {
		@extend .title;
		font-size: var(--font-size-xs);
  }

	h5, h6 {
		font-family: var(--gravesend-sans);
		font-weight: var(--font-weight-bold);
		font-size: var(--font-size-xs);
	}


	// Paragraphs

	p,ul,ol {
		// color: var(--color-blue-text);

		a {
			// color: var(--color-blue-dark);
			text-decoration: underline;
			color: inherit;
		}

		a:hover {
			opacity: 0.5;
		}

		& > strong {
      font-weight: var(--font-weight-bold);
		}

		& > em {
			font-style: italic;
		}
					
	}

	p {
		line-height: var(--line-height-md);
	}

	sup {
		font-size: 75%;
	}

	ul, ol {

	}

	ul {
		list-style-type: disc;
		padding-left: var(--padding-sm);
	}

	ol {
		list-style-type: decimal;
		padding-left: var(--padding-sm);
		counter-reset: item;
	}

	ul ul {
		margin: pxRem(10) 0 0 pxRem(30);
		list-style-type: circle;
	}

	li {		
		line-height: var(--line-height-lg);
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		margin: var(--padding-md) auto;
	}

	blockquote {
		display: block;
		padding: var(--padding-xl) var(--padding-md);
		margin: var(--padding-xl) 0;

		border-top: 1px solid var(--color-grey-light);
		border-bottom: 1px solid var(--color-grey-light);

		text-align: center;

			p {
				font-family: var(--loos-extended);
				font-size: var(--font-size-md);
				line-height: var(--line-height-sm);
				font-style: normal;
				color: var(--color-dark-peak);

					&:before {
						content: open-quote;
					}

					&:after {
						content: close-quote;
					}

			}

	}

	address {
		font-style: normal;
	}


}

// WP overrides

.std-content {

	img.alignleft,
	img.alignright {
		width: 50%;
		margin: var(--padding-md) auto;

			@include mq(screen-xs) {
				width: 20%;
				clear: both;
				margin-top: 0.5rem;
				margin-bottom: var(--padding-sm);
			}

			@include mq(screen-lg) {
				width: 25%;
			}

	}

	img.alignleft {
		
		@include mq(screen-xs) {
			float: left;
			margin-right: var(--padding-md);
		}

	}

	img.alignright {
		
		@include mq(screen-xs) {
			float: right;
			margin-left: var(--padding-md);
		}

	}

	img.alignnone {
		clear: both;
		margin: var(--padding-lg) auto;
	}

	.wp-caption {
		width: 100% !important;
		height: auto;
		margin: var(--padding-lg) auto;


			img {
				margin: 0;
			}
			
	}

	p.wp-caption-text {
		margin: 0 auto;
		padding: 1rem;

		font-weight: var(--font-weight-bold);
		line-height: var(--line-height-sm);
		text-align: center;
    color: var(--color-blue-dark);

		background-color: var(--color-light-peak);
	}

}

.std-content iframe,
.video-embed iframe {
  width: 100%;
  height: 100%;

  aspect-ratio: 16 / 9;
}