.collab-hero {
  position: relative;
  padding: clamp(5rem, 3.1250rem + 9.3750vw, 12.5000rem) 0 clamp(2.5rem, 1.5625rem + 4.6875vw, 6.2500rem);;

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 50%;
      height: 100%;
    }
}

.collab-hero__content {
  position: relative;
  z-index: 2;

  display: grid;
  gap: var(--padding-lg);
  padding: var(--padding-xl) 0 0;

    > .inner {
      position: relative;
      z-index: 2;
    }
}

.collab-hero-imgs {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);

    @include mq(screen-xs) {
      gap: 0;
    }

}

.collab-hero-sticker {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(35%, -50%);
  z-index: 2;

  display: block;
  width: min(pxRem(150), 25%);
  height: auto;
}

.collab-hero-product {
  position: relative;
  z-index: 1;
  display: grid;
  place-items: center;

  width: 75%;

    @include mq(screen-xs) {
      order: 2;
      width: pxRem(240);
      padding: 0;
    }

    @include mq(screen-sm) {
      width: pxRem(280);
    }

    @include mq(screen-lg) {
      width: pxRem(450);
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      width: 100%;
    }

    img {
      position: relative;
      z-index: 2;

      width: 80%;
    }

}

.collab-hero-logo {
  width: min(250px, 30%);
  opacity: 0.25;

    // @include mq(screen-xs) {
    //   opacity: 0.25;
    // }
  
    svg,
    img {
      display: block;
      width: 100%;
    }

}

.collab-hero-logo--buxton {
  
  @include mq(screen-xs) {
    order: 1;
  }

}

.collab-hero-logo--collab {

  @include mq(screen-xs) {
    order: 3;
  }

}

.collab-hero-text {
  display: grid;
  gap: var(--padding-md);
  justify-items: center;
  text-align: center;
}


.collab-product-info {
  display: grid;
  align-items: start;

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1.5fr;
      gap: var(--padding-lg);
    }

}

.collab-product-info__soon {
  @include mq(screen-sm) {
    align-items: center;
  }
}

.collab-product-img {
  position: relative;
  display: grid;
  place-items: center;
}

.collab-product-img__product {
  position: relative;
  z-index: 1;
  display: block;
  width: auto;
  height: clamp(17.5rem, 10.8333rem + 33.3333vw, 37.5000rem); /* 280px @ 320px > 600px @ 1280px */
}

.collab-product-img__sticker {
  position: absolute;
  transform: scale(0.5);
  z-index: 2;

  display: block;
  width: min(pxRem(100), 20%);
  height: auto;

  transition: all 0.25s ease-in;
  opacity: 0;
  transform: scale(0.75);

    &.is-visible {
      transition: all 0.25s ease-out;
      opacity: 1;
      transform: scale(1);
    }

}

.collab-hero-imgs {

  .collab-product-img__sticker {
    top: -5%;
    left: 57.5%;
  }

}

.collab-product-img {
  
  .collab-product-img__sticker {
    top: -5%;
    right: 57.5%;
  }

}

.collab-product-content {
  display: grid;
  gap: var(--padding-xl);
  justify-items: start;
  padding: var(--padding-xl) var(--padding-lg);

  background-color: var(--color-light-peak);

    .product-details__block {
      display: grid;
      gap: var(--padding-sm);
      width: 100%;
    }

    .product-details__block:nth-child(1) {

      @include mq(screen-md) {
        width: calc(50% - calc(var(--padding-xl)/2));
      }

    }

    .product-details__block:nth-child(2) {

      @include mq(screen-md) {
        width: calc(50% - calc(var(--padding-xl)/2));
      }

    }

    .product-details__block:nth-child(3) {
    
    }

    .product-color {
      aspect-ratio: 2/1;
    }

}

.coming-soon-flag {
  display: block;
  padding: var(--padding-xs) var(--padding-md);
  margin: var(--padding-sm) 0 0;

  background-color: red;

  font-family: var(--gravesend-sans);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  color: white;
}

.coming-soon-cta {
  display: grid;
  justify-items: start;
  gap: var(--padding-lg);
  padding: var(--padding-xl);
}

.coming-soon-cta__title {
  font-family: var(--loos-extended);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);

    i {
      font-family: var(--loos-compressed);
      font-style: normal;
    }
  
  
}

// Animation

@include mq(screen-sm) {

  .collab-hero-product img {
    transition: all 0.5s ease-out;
    transform-origin: bottom center;
  }

  .collab-hero-product svg, 
  .collab-hero-sticker,
  .collab-hero-logo--buxton,
  .collab-hero-logo--collab {
    transition: all 0.25s ease-out;
  }

  .collab-hero-imgs.has-faded {

    .collab-hero-product img {
      opacity: 0;
      transform: translateY(-5%);
      transition: all 0.5s ease-in;
    }

    .collab-hero-product svg, 
    .collab-hero-sticker,
    .collab-hero-logo--buxton,
    .collab-hero-logo--collab {
      transition: all 0.25s ease-in;
    }

    .collab-hero-product svg {
      opacity: 0;
      transform: translate(-50%, -50%) scale(3);
    }

    .collab-hero-logo--buxton {
      transform: translateX(50%);
    }

    .collab-hero-logo--collab {
      transform: translateX(-50%);
    }

    .collab-hero-sticker {
      opacity: 0;
    }

  }

}


// Variants

.collab-hero--dark {
  background-color: black;

    &:after {
      background-color: rgba(255,255,255,0.15);
    }

    .collab-hero-logo > svg {
      fill: white;
    }

    .stroke-btn {
      color: white;

        &:hover {
          background-color: rgba(white, 0.1);
        }

    }

}

.collab-hero--light {
  background-color: var(--color-light-peak);

    &:after {
      background-color: white;
    }

    .stroke-btn {
      color: black;

        &:hover {
          background-color: rgba(black, 0.1);
        }
        
    }
    
}