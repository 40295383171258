.locations-outer {
  display: grid;
  gap: var(--padding-lg);
}

.locations {
  display: grid;
  gap: var(--padding-lg);

    @include mq(screen-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-lg) {
      grid-template-columns: repeat(3, 1fr);
    }

}

.location {
  background-color: var(--color-light-peak);
    
    @include mq(screen-xs) {
      display: flex;
      flex-direction: column;
    }

}

.map-cta {
  
  .location {
    background: none;

    text-align: center;

      @include mq(screen-sm) {
        text-align: left;
      }
      
  }

  strong {
    font-weight: var(--font-weight-bold);
  }

}

.location__img {
  aspect-ratio: 16/9;
  overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

}

.location__info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: var(--padding-sm);
  padding: var(--padding-lg) var(--padding-lg) calc(var(--btn-height) + var(--padding-lg) + var(--padding-sm));

    @include mq(screen-sm) {
      flex: 1;
    }

    .btn {
      position: absolute;
      bottom: var(--padding-lg);
      left: var(--padding-lg);
      z-index: 2;
    }

}
