.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;

  background-color: var(--color-white-fade-80);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;


    &.is-visible {
      visibility: visible;
      opacity: 1;

        .popup-modal {
          transform: translateY(-1rem);
        }

    }

}

.popup-modal {
  position: relative;
  width: min(80%, 30rem);

  background-color: var(--color-midnight-black);

  transition: all 0.5s;
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  
  display: grid;
  place-items: center;
  width: var(--btn-height);
  height: var(--btn-height);

  background-color: rgba(255,255,255,0.5);

  cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      width: pxRem(20);
      height: pxRem(20);

      fill: var(--color-dark-peak);
    }
}

.popup-modal__img-outer {
  position: relative;
  z-index: 1;

  &:after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    
    width: 100%;
    height: 100%;

    background: linear-gradient(0deg, rgba(16,29,46,1) 0%, rgba(16,29,46,0) 100%);
    opacity: 1;
  }

}

.popup-modal__img {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  height: auto;
}

.popup-modal__content {
  position: relative;
  z-index: 1;

  display: grid;
  justify-items: center;
  gap: var(--padding-md);
  padding: 0 var(--padding-md) var(--padding-lg);

  text-align: center;
}

.popup-modal__logo {
  width: 33.333%;

    svg {
      width: 100%;

      fill: white;
    }

}

.popup-no {
  margin: pxRem(10) 0 0;

  font-family: var(--loos-extended);
  font-size: 0.75rem;
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: white;
  line-height: 1;
  opacity: 0.5;

  background: none;

    &:hover {
      text-decoration: underline;
      opacity: 1;
    }

}

.popup-modal__terms-link {
  padding: var(--padding-md) 0 0;
  font-size: pxRem(12);
  color: white;
  text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

}
