/*!
Theme Name: Buxton Brewery
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/
:root {
  --font-size-xxl: clamp(2.25rem, 1.5000rem + 3.7500vw, 4.5000rem); /* 36px @ 320px > 72px @ 1280px */
  --font-size-xl: clamp(2rem, 1.6667rem + 1.6667vw, 3.0000rem); /* 32px @ 320px > 48px @ 1280px */
  --font-size-lg: clamp(1.8rem, 1.6500rem + 0.7500vw, 2.2500rem); /* 28.8px @ 320px > 36px @ 1280px */
  --font-size-md: clamp(1.35rem, 1.2375rem + 0.5625vw, 1.6875rem); /* 21.6px @ 320px > 27px @ 1280px */
  --font-size-sm: clamp(1.05rem, 0.9625rem + 0.4375vw, 1.3125rem); /* 16.8px @ 320px > 21px @ 1280px */
  --font-size-xs: clamp(0.9rem, 0.8250rem + 0.3750vw, 1.1250rem); /* 14.4px @ 320px > 18px @ 1280px */
  --font-size-xxs: 1rem;
  --gravesend-sans: gravesend-sans, sans-serif;
  --loos-compressed: loos-compressed, sans-serif;
  --loos-normal: loos-normal, sans-serif;
  --loos-extended: loos-extended, sans-serif;
  --loos-medium: 500;
  --font-weight-bold: 700;
  --line-height-sm: 1.33;
  --line-height-md: 1.66;
  --line-height-lg: 2;
  --loos-kerning: -0.05rem;
  --loos-line-height: 0.8;
  --padding-xxl: clamp(3.125rem, 1.6667rem + 7.2917vw, 7.5000rem); /* 50px @ 320px > 120px @ 1280px */
  --padding-xl: clamp(2.4rem, 2.2000rem + 1.0000vw, 3.0000rem); /* 38.4px @ 320px > 48px @ 1280px */
  --padding-lg: clamp(1.6rem, 1.4667rem + 0.6667vw, 2.0000rem); /* 25.6px @ 320px > 32px @ 1280px */
  --padding-md: clamp(1rem, 0.9167rem + 0.4167vw, 1.2500rem); /* 16px @ 320px > 20px @ 1280px */
  --padding-sm: clamp(0.8rem, 0.7333rem + 0.3333vw, 1.0000rem); /* 12.8px @ 320px > 16px @ 1280px */
  --padding-xs: 0.5rem;
  --padding-xxs: 0.25rem;
  --color-grey: #8D8D8D;
  --color-grey-light: #EEE9E2;
  --color-light-peak: #f7f4f3;
  --color-midnight-black: #101d2e;
  --color-cavern-blue: #125564;
  --color-cavern-blue-hover: #104855;
  --color-dark-peak: #324142;
  --color-dark-peak-hover: #242F30;
  --color-blue-john: #0093D3;
  --color-blue-john-hover: #007DB3;
  --color-white-fade-10: rgba(255,255,255,0.1);
  --color-white-fade-50: rgba(255,255,255,0.5);
  --color-white-fade-80: rgba(255,255,255,0.8);
  --color-dark-peak-fade-80: rgba(50,65,66, 0.8);
  --color-dark-peak-fade-10: rgba(50,65,66, 0.1);
  --color-error: #C63636;
  --mq-xxs: 450px;
  --mq-xs: 640px;
  --mq-sm: 768px;
  --mq-md: 1024px;
  --mq-lg: 1280px;
  --mq-xl: 1536px;
  --mq-xxl: 1800px;
  --btn-height: 2.5rem;
  --paint-daub-padding: clamp(2.5rem, 1.0811rem + 7.0946vw, 9.0625rem);
  --paint-daub-max-height: clamp(1.875rem, -0.5208rem + 11.9792vw, 9.0625rem); /* 30px @ 320px > 145px @ 1280px */
}

html {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--loos-normal);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
  color: var(--color-dark-peak);
  background-color: white;
}

[id] {
  scroll-margin-top: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: normal;
}

p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-weight: normal;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

strong {
  font-weight: normal;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
  }
}
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=date],
input[type=month],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=week],
input[type=number],
input[type=search],
input[type=tel],
input[type=color],
textarea {
  font-size: inherit;
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}

input[type=submit],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=number],
input[type=search],
input[type=tel] {
  height: var(--btn-height);
  padding: 0 var(--padding-xs);
  border: 1px solid var(--color-dark-peak);
}
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=time]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=tel]:focus {
  outline: none;
  border: 3px solid var(--color-blue-john);
}

select {
  font-size: inherit;
}

button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.screen-reader-text {
  display: none;
}

ul, ol {
  list-style-type: none;
}

.align-center {
  text-align: center;
}

.screen-reader-only {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

.bg-dark-peak {
  background-color: var(--color-dark-peak);
}

.bg-light-peak {
  background-color: var(--color-light-peak);
}

.bg-white {
  background-color: white;
}

.site {
  position: relative;
}

.site-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.site-bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}

.site-bg__img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  opacity: 0.5;
}

.content {
  position: relative;
  z-index: 2;
}

.main {
  overflow: hidden;
}

.outer {
  position: relative;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--padding-lg);
}

.inner--sm {
  max-width: var(--mq-sm);
}

.inner--md {
  max-width: var(--mq-md);
}

.inner--lg {
  max-width: var(--mq-lg);
}

.inner--xl {
  max-width: var(--mq-xl);
}

.inner--xxl {
  max-width: var(--mq-xxl);
}

.mt-xs {
  margin-top: var(--padding-xs);
}

.mt-sm {
  margin-top: var(--padding-sm);
}

.mt-md {
  margin-top: var(--padding-md);
}

.mt-lg {
  margin-top: var(--padding-lg);
}

.mt-xl {
  margin-top: var(--padding-xl);
}

.mb-xs {
  margin-bottom: var(--padding-xs);
}

.mb-sm {
  margin-bottom: var(--padding-sm);
}

.mb-md {
  margin-bottom: var(--padding-md);
}

.mb-lg {
  margin-bottom: var(--padding-lg);
}

.mb-xl {
  margin-bottom: var(--padding-xl);
}

.mb-xxl {
  margin-bottom: var(--padding-xxl);
}

.mtb-xs {
  margin: var(--padding-xs) 0;
}

.mtb-sm {
  margin: var(--padding-sm) 0;
}

.mtb-md {
  margin: var(--padding-md) 0;
}

.mtb-lg {
  margin: var(--padding-lg) 0;
}

.mtb-xl {
  margin: var(--padding-xl) 0;
}

.m-xs {
  margin: var(--padding-xs);
}

.m-sm {
  margin: var(--padding-sm);
}

.m-md {
  margin: var(--padding-md);
}

.m-lg {
  margin: var(--padding-lg);
}

.m-xl {
  margin: var(--padding-xl);
}

.pt-xs {
  padding-top: var(--padding-xs);
}

.pt-sm {
  padding-top: var(--padding-sm);
}

.pt-md {
  padding-top: var(--padding-md);
}

.pt-lg {
  padding-top: var(--padding-lg);
}

.pt-xl {
  padding-top: var(--padding-xl);
}

.pb-xs {
  padding-bottom: var(--padding-xs);
}

.pb-sm {
  padding-bottom: var(--padding-sm);
}

.pb-md {
  padding-bottom: var(--padding-md);
}

.pb-lg {
  padding-bottom: var(--padding-lg);
}

.pb-xl {
  padding-bottom: var(--padding-xl);
}

.ptb-xs {
  padding: var(--padding-xs) 0;
}

.ptb-sm {
  padding: var(--padding-sm) 0;
}

.ptb-md {
  padding: var(--padding-md) 0;
}

.ptb-lg {
  padding: var(--padding-lg) 0;
}

.ptb-xl {
  padding: var(--padding-xl) 0;
}

.p-xs {
  padding: var(--padding-xs);
}

.p-sm {
  padding: var(--padding-sm);
}

.p-md {
  padding: var(--padding-md);
}

.p-lg {
  padding: var(--padding-lg);
}

.p-xl {
  padding: var(--padding-xl);
}

.pre-header {
  display: flex;
  justify-content: center;
  gap: var(--padding-xl);
  padding: 0.3125rem var(--padding-md);
  background-color: var(--color-cavern-blue);
}

.pre-header__cta {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-family: var(--loos-compressed);
  font-size: var(--font-size-xxs);
  text-transform: uppercase;
  color: white;
  text-align: center;
  text-decoration: none;
}
.pre-header__cta:hover {
  text-decoration: underline;
}
.pre-header__cta:hover svg {
  opacity: 1;
}
@media (min-width: 1024px) {
  .pre-header__cta {
    font-family: var(--gravesend-sans);
  }
}
.pre-header__cta svg {
  width: 0.9375rem;
  height: 0.9375rem;
  fill: currentColor;
  opacity: 0.25;
}

.pre-header__cta:last-child {
  display: none;
}
@media (min-width: 1024px) {
  .pre-header__cta:last-child {
    display: flex;
  }
}

.pg-header {
  position: relative;
  z-index: 5000;
  display: grid;
  place-items: center;
  padding: var(--padding-md) 0.3125rem var(--padding-xs) var(--padding-md);
  background-color: var(--color-light-peak);
}
@media (min-width: 768px) {
  .pg-header {
    padding: var(--padding-lg) var(--padding-md) var(--padding-xs);
  }
}
@media (min-width: 1536px) {
  .pg-header {
    padding: var(--padding-lg) var(--padding-xl) var(--padding-xs);
  }
}

.woocommerce-cart .pg-header {
  z-index: 2;
}

.paint-daub {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
}
@media (min-width: 1800px) {
  .paint-daub {
    width: var(--mq-xxl);
    height: var(--paint-daub-max-height);
  }
}
.paint-daub svg {
  width: 100%;
  height: auto;
  fill: var(--color-light-peak);
}

.main-nav--left {
  display: none;
}
@media (min-width: 1024px) {
  .main-nav--left {
    display: block;
    position: absolute;
    top: 50%;
    left: var(--padding-lg);
    transform: translateY(-50%);
    z-index: 1;
  }
}
@media (min-width: 1536px) {
  .main-nav--left {
    left: var(--padding-xl);
  }
}

@media (min-width: 1024px) {
  .main-nav--right {
    position: absolute;
    top: 50%;
    right: var(--padding-lg);
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    gap: var(--padding-xl);
  }
}
@media (min-width: 1536px) {
  .main-nav--right {
    right: var(--padding-xl);
  }
}

.main-nav--right .main-nav__list {
  display: none;
}

@media (min-width: 1024px) {
  .main-nav--left .main-nav__list,
  .main-nav--right .main-nav__list {
    display: flex;
    gap: var(--padding-xl);
  }
}
.main-nav--left .main-nav__list li,
.main-nav--right .main-nav__list li {
  line-height: 1;
}

.main-nav__list a {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xs);
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  text-decoration: none;
  line-height: 1;
}
.main-nav__list a:hover {
  color: var(--color-midnight-black);
  text-decoration: underline;
}

.pg-header__logo {
  display: block;
  width: 7.5rem;
  margin: 0.625rem 0 0;
  line-height: 1;
}
.pg-header__logo svg {
  fill: var(--color-dark-peak);
}
@media (min-width: 768px) {
  .pg-header__logo {
    width: 9.375rem;
    margin: 0;
  }
}

.mobile-nav {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10000;
  width: 100%;
  padding: var(--padding-lg) var(--padding-md) var(--padding-xl);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--color-light-peak);
}
.mobile-nav.is-open {
  display: block;
}
@media (min-width: 1024px) {
  .mobile-nav.is-open {
    display: none;
  }
}
.mobile-nav .main-nav__list {
  display: grid;
  gap: var(--padding-lg);
}
.mobile-nav a {
  display: block;
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}

.toggle-mobile-nav {
  position: absolute;
  top: calc(50% - 1.25rem);
  left: var(--padding-md);
  z-index: 1;
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.625rem;
  background: none;
}
.toggle-mobile-nav svg {
  fill: var(--color-dark-peak);
}
@media (min-width: 1024px) {
  .toggle-mobile-nav {
    display: none;
  }
}

.toggle-mobile-nav.is-active {
  background-color: var(--color-dark-peak);
}
.toggle-mobile-nav.is-active svg {
  fill: white;
}

.pg-header__actions {
  display: flex;
  position: absolute;
  top: calc(50% - 1.25rem);
  right: 0;
  z-index: 1;
  gap: var(--padding-xs);
}
@media (min-width: 1024px) {
  .pg-header__actions {
    position: relative;
    top: auto;
    gap: var(--padding-md);
  }
}

.pg-header__basket {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-xxs);
  height: 2.5rem;
  padding: 0 0.3125rem;
  font-family: var(--loos-compressed);
  font-size: var(--font-size-xxs);
  text-decoration: none;
  color: var(--color-dark-peak);
}
@media (min-width: 768px) {
  .pg-header__basket {
    padding: 0 0.625rem;
  }
}
@media (min-width: 1024px) {
  .pg-header__basket {
    right: 0;
  }
}
.pg-header__basket svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
}
.pg-header__basket:hover {
  background-color: var(--color-dark-peak);
  color: white;
}

.pg-header__basket__label {
  display: none;
}

.dark-theme .main-nav__list a,
.dark-theme .pg-header__basket {
  color: var(--color-light-peak);
}
.dark-theme .main-nav__list a:hover,
.dark-theme .pg-header__basket:hover {
  color: white;
}
.dark-theme .pg-header__logo svg {
  fill: var(--color-light-peak);
}
.dark-theme .toggle-mobile-nav {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.625rem;
  background: none;
}
.dark-theme .toggle-mobile-nav svg {
  fill: var(--color-light-peak);
}
@media (min-width: 768px) {
  .dark-theme .toggle-mobile-nav {
    display: none;
  }
}
.dark-theme .toggle-mobile-nav.is-active {
  background-color: var(--color-light-peak);
}
.dark-theme .toggle-mobile-nav.is-active svg {
  fill: black;
}

.dark-theme .pg-header,
.light-theme .pg-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: none;
}

.pg-footer-outer {
  position: relative;
  background-color: var(--color-dark-peak);
  overflow: hidden;
}
.pg-footer-outer .inner {
  z-index: 2;
}

.pg-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-lg);
  padding: var(--padding-xl) 0;
}

.pg-footer__logo {
  width: 100%;
  padding: 0 0 var(--padding-lg);
  border-bottom: 1px solid var(--color-white-fade-10);
}

.pg-footer__logo svg {
  display: block;
  width: 9.375rem;
  margin: 0 auto;
  fill: white;
}

.pg-footer__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
}
@media (min-width: 640px) {
  .pg-footer__nav {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--padding-md) var(--padding-lg);
  }
}
.pg-footer__nav li {
  text-align: center;
}
.pg-footer__nav a {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  line-height: 1;
}
.pg-footer__nav a:hover {
  text-decoration: underline;
}

.pg-footer-icons-outer {
  padding: var(--padding-xl) 0;
  background-color: white;
}

.pg-footer-icons {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .pg-footer-icons {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pg-footer-icons__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
  text-align: center;
}

.social-list,
.payments-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.pg-footer-outer .social-list,
.pg-footer-outer .payments-list {
  justify-content: center;
}

.payments-list__icon {
  display: grid;
  place-items: center;
  width: 3.125rem;
  height: 2.5rem;
}
.payments-list__icon img,
.payments-list__icon svg {
  display: block;
  width: 100%;
  height: auto;
}

.social-list__link {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
.social-list__link img,
.social-list__link svg {
  display: block;
  width: 100%;
  height: auto;
}

.copyright {
  padding: var(--padding-lg);
  text-align: center;
  background-color: var(--color-light-peak);
}

.title, .woocommerce-cart .cart-empty, .order-total th, .std-content h4, .std-content h3, .std-content h2, .product-attr, .woocommerce-checkout .wc-block-components-totals-footer-item .wc-block-components-totals-item__label, .woocommerce-checkout h2 {
  font-family: var(--gravesend-sans);
  line-height: 1;
  text-transform: uppercase;
}

.title--xxl {
  font-size: var(--font-size-xxl);
}

.title--xl {
  font-size: var(--font-size-xl);
}

.title--lg {
  font-size: var(--font-size-lg);
}

.title--md, .order-total th, .std-content h2, .woocommerce-checkout .wc-block-components-totals-footer-item .wc-block-components-totals-item__label, .woocommerce-checkout h2 {
  font-size: var(--font-size-md);
}

.title--sm, .woocommerce-cart .cart-empty, .std-content h3, .product-attr {
  font-size: var(--font-size-sm);
}

.text-white {
  color: white;
}

.text-error {
  color: var(--color-error);
}

a.title, .woocommerce-cart a.cart-empty, a.product-attr, .woocommerce-checkout .wc-block-components-totals-footer-item a.wc-block-components-totals-item__label {
  color: inherit;
  text-decoration: none;
}
a.title:hover, .woocommerce-cart a.cart-empty:hover, a.product-attr:hover, .woocommerce-checkout .wc-block-components-totals-footer-item a.wc-block-components-totals-item__label:hover {
  text-decoration: underline;
}

.stroke-btn {
  display: inline-block;
  height: var(--btn-height);
  padding: 0 var(--padding-sm);
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid;
  background: none;
  cursor: pointer;
}

.stroke-btn--white {
  color: white;
}
.stroke-btn--white:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.stroke-btn--dark-peak {
  color: var(--color-dark-peak);
}
.stroke-btn--dark-peak:hover {
  background-color: var(--color-dark-peak-fade-10);
}

.btn, .filter-action {
  display: inline-block;
  height: var(--btn-height);
  padding: 0 var(--padding-sm);
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border: none;
  cursor: pointer;
}

.btn--dark-peak, .filter-action, .wc-block-components-checkout-place-order-button {
  color: white;
  background-color: var(--color-dark-peak);
}
.btn--dark-peak:hover, .filter-action:hover, .wc-block-components-checkout-place-order-button:hover {
  background-color: var(--color-dark-peak-hover);
}

.btn--blue-john {
  color: white;
  background-color: var(--color-blue-john);
}
.btn--blue-john:hover {
  background-color: var(--color-blue-john-hover);
}

.btn--cavern-blue {
  color: white;
  background-color: var(--color-cavern-blue);
}
.btn--cavern-blue:hover {
  background-color: var(--color-cavern-blue-hover);
}

.text-btn, .gift-cards .reset_delivery_date, .wc-block-components-checkout-return-to-cart-button {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  line-height: 1;
  background: none;
}
.text-btn:hover, .gift-cards .reset_delivery_date:hover, .wc-block-components-checkout-return-to-cart-button:hover {
  color: var(--color-midnight-black);
  text-decoration: underline;
}

.submit-btn {
  display: inline-block;
  height: var(--btn-height);
  padding: 0 var(--padding-sm);
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border: none;
  cursor: pointer;
  color: white;
  background-color: var(--color-dark-peak);
}
.submit-btn:hover {
  background-color: var(--color-dark-peak-hover);
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.3333333333%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: 1.4285714286%;
  height: 10px;
  float: left;
}


.wp-block-woocommerce-checkout,
.wp-block-woocommerce-cart {
  position: relative;
  z-index: 2;
}

div.woocommerce {
  padding: var(--paint-daub-max-height) 0 0;
}

.text-hero + div.woocommerce {
  padding: 0;
}

.woocommerce-notices-wrapper {
  margin: 0 0 var(--padding-lg);
}

.woocommerce-message,
.woocommerce-error li {
  font-weight: var(--font-weight-bold);
}
.woocommerce-message a,
.woocommerce-error li a {
  color: inherit;
}
.woocommerce-message a:hover,
.woocommerce-error li a:hover {
  opacity: 0.5;
}

.single-product .woocommerce-notices-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  width: 100%;
  padding: 0 var(--padding-md);
}
@media (min-width: 768px) {
  .single-product .woocommerce-notices-wrapper {
    position: absolute;
    top: var(--paint-daub-max-height);
    left: 50%;
    transform: translate(-50%, 0);
  }
}
@media (min-width: 1280px) {
  .single-product .woocommerce-notices-wrapper {
    width: var(--mq-lg);
  }
}

.woocommerce-message {
  padding: var(--padding-md);
  border: 1px solid #80A06E;
  background-color: #EDF1EA;
}

.woocommerce-error {
  padding: var(--padding-md);
  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.woocommerce-checkout .wp-block-woocommerce-checkout-order-summary-block {
  padding: var(--padding-lg);
  background-color: var(--color-light-peak);
}
.woocommerce-checkout .wc-block-components-totals-coupon a {
  color: var(--color-dark-peak);
}
.woocommerce-checkout .wc-block-formatted-money-amount {
  font-family: var(--loos-compressed);
  letter-spacing: var(--loos-kerning);
  color: var(--color-dark-peak);
}
.woocommerce-checkout .wc-block-components-order-summary__button-text,
.woocommerce-checkout .wc-block-components-totals-item__label {
  font-weight: var(--font-weight-bold);
}
.woocommerce-checkout .wc-block-components-totals-footer-item .wc-block-components-totals-item__label {
  font-weight: normal;
}

.woocommerce-table--order-details {
  width: 100%;
}

.wc-block-components-form .wc-block-components-text-input input[type=email], .wc-block-components-form .wc-block-components-text-input input[type=number], .wc-block-components-form .wc-block-components-text-input input[type=tel], .wc-block-components-form .wc-block-components-text-input input[type=text], .wc-block-components-form .wc-block-components-text-input input[type=url], .wc-block-components-text-input input[type=email], .wc-block-components-text-input input[type=number], .wc-block-components-text-input input[type=tel], .wc-block-components-text-input input[type=text], .wc-block-components-text-input input[type=url], .wc-block-components-country-input input[type=text] {
  height: 3.75rem;
  border-radius: 0 !important;
  -webkit-appearance: none;
}
.wc-block-components-form .wc-block-components-text-input input[type=email]:focus, .wc-block-components-form .wc-block-components-text-input input[type=number]:focus, .wc-block-components-form .wc-block-components-text-input input[type=tel]:focus, .wc-block-components-form .wc-block-components-text-input input[type=text]:focus, .wc-block-components-form .wc-block-components-text-input input[type=url]:focus, .wc-block-components-text-input input[type=email]:focus, .wc-block-components-text-input input[type=number]:focus, .wc-block-components-text-input input[type=tel]:focus, .wc-block-components-text-input input[type=text]:focus, .wc-block-components-text-input input[type=url]:focus, .wc-block-components-country-input input[type=text]:focus {
  outline: none;
  border: 3px solid var(--color-blue-john) !important;
}

.wc-block-components-checkout-place-order-button {
  display: inline-block !important;
  height: var(--btn-height) !important;
  padding: 0 var(--padding-sm) !important;
  font-family: var(--loos-extended) !important;
  font-size: var(--font-size-xxs) !important;
  line-height: var(--btn-height) !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  text-align: center !important;
  border: none !important;
  cursor: pointer !important;
}

.wc-block-components-checkout-return-to-cart-button {
  padding: 0 !important;
  text-decoration: underline !important;
}
.wc-block-components-checkout-return-to-cart-button svg {
  display: none !important;
}

.order-review {
  display: grid;
  gap: var(--padding-lg);
}
.order-review .woocommerce-order-overview strong {
  font-weight: var(--font-weight-bold);
}
.order-review .woocommerce-order-details {
  display: grid;
  gap: var(--padding-lg);
  padding: var(--padding-lg) 0 0;
  border-top: 1px solid var(--color-grey-light);
}
.order-review .shop_table {
  padding: var(--padding-md);
  background-color: var(--color-light-peak);
}
.order-review .shop_table td, .order-review th {
  padding: 0 0 var(--padding-sm) !important;
}
.order-review .shop_table th {
  font-weight: var(--font-weight-bold);
}
.order-review .woocommerce-Price-amount {
  font-family: var(--loos-compressed);
  letter-spacing: var(--loos-kerning);
  color: var(--color-dark-peak);
}

.variation {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 0;
  margin: var(--padding-xs) 0 0;
  font-size: 12px;
}

.variation dd,
.variation dt {
  margin: 0;
  padding: 0;
}

.hero-slider-outer {
  position: relative;
}

.hero-slider {
  position: relative;
  z-index: 1;
}

.hero-slide,
.hero-banner {
  position: relative;
  z-index: 1;
  width: 100%;
}

.hero-slide__img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .hero-slide__img {
    height: 50rem;
    aspect-ratio: auto;
  }
}
.hero-slide__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: block;
  width: auto;
  height: 100%;
}
@media (min-width: 1280px) {
  .hero-slide__img img {
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
  }
}
.hero-slide__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.95;
  transition: all 0.5s ease-out;
}

.hero-banner__img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .hero-banner__img {
    height: 37.5rem;
    aspect-ratio: auto;
  }
}
.hero-banner__img img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.hero-banner__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.95;
  transition: all 0.5s ease-out;
}

.hero-slide__text-outer,
.hero-banner__text-outer {
  position: absolute;
  bottom: 6.25rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 100%;
  padding: 0 var(--padding-lg);
}
@media (min-width: 768px) {
  .hero-slide__text-outer,
  .hero-banner__text-outer {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .hero-slide__text-outer,
  .hero-banner__text-outer {
    bottom: 7.5rem;
  }
}
@media (min-width: 1280px) {
  .hero-slide__text-outer,
  .hero-banner__text-outer {
    width: var(--mq-lg);
  }
}

.hero-slide__text,
.hero-banner__text {
  display: grid;
  gap: var(--padding-md);
}
@media (min-width: 1024px) {
  .hero-slide__text,
  .hero-banner__text {
    max-width: 50%;
  }
}

.hero-slider-nav {
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  gap: 0.3125rem;
}

.hero-slider-nav__btn {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  background: rgba(255, 255, 255, 0.15);
  border: none;
}
.hero-slider-nav__btn:hover, .hero-slider-nav__btn.tns-nav-active {
  background-color: rgba(255, 255, 255, 0.3);
}

.hero-slide__text-outer .hero-slide__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (min-width: 1024px) {
  .hero-slide__text-outer--center {
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .hero-slide__text-outer--left {
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .hero-slide__text-outer--left .hero-slide__text {
    align-items: flex-start;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .hero-slide__text-outer--right {
    justify-content: flex-end;
  }
}
@media (min-width: 1024px) {
  .hero-slide__text-outer--right .hero-slide__text {
    align-items: flex-start;
    text-align: left;
  }
}

.brand-strap {
  padding: var(--padding-lg);
}
.brand-strap p {
  font-family: var(--loos-extended);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  text-align: center;
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);
}
.brand-strap i {
  font-family: var(--loos-compressed);
  font-style: normal;
}

.brand-strap.bg-dark-peak {
  background-color: var(--color-dark-peak);
}
.brand-strap.bg-dark-peak p {
  color: white;
}

.product-feed {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 450px) {
  .product-feed {
    grid-template-columns: repeat(2, 1fr);
  }
}

.product-feed__item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-feed__item:hover .title, .product-feed__item:hover .woocommerce-cart .cart-empty, .woocommerce-cart .product-feed__item:hover .cart-empty, .product-feed__item:hover .order-total th, .order-total .product-feed__item:hover th, .product-feed__item:hover .std-content h4, .std-content .product-feed__item:hover h4, .product-feed__item:hover .std-content h3, .std-content .product-feed__item:hover h3, .product-feed__item:hover .std-content h2, .std-content .product-feed__item:hover h2, .product-feed__item:hover .product-attr, .product-feed__item:hover .woocommerce-checkout h2, .woocommerce-checkout .product-feed__item:hover h2, .product-feed__item:hover .woocommerce-checkout .wc-block-components-totals-footer-item .wc-block-components-totals-item__label, .woocommerce-checkout .wc-block-components-totals-footer-item .product-feed__item:hover .wc-block-components-totals-item__label {
  text-decoration: underline;
}
.product-feed__item:hover .product-feed__img {
  transform: scale(1.05);
  transition: transform 0.25s ease-out;
}
.product-feed__item:hover .btn--dark-peak, .product-feed__item:hover .filter-action, .product-feed__item:hover .wc-block-components-checkout-place-order-button {
  background-color: var(--color-dark-peak-hover);
}

.product-feed__flags {
  position: absolute;
  top: 0;
  right: 1.25rem;
  z-index: 2;
  display: grid;
  gap: 2px;
}
.product-feed__flags img {
  display: block;
  width: 100%;
  height: auto;
}

.product-feed__sale {
  position: absolute;
  top: 0;
  left: 1.25rem;
  z-index: 2;
  display: grid;
  gap: 2px;
}
.product-feed__sale img {
  display: block;
  width: 100%;
  height: auto;
}

.product-feed .product-feed__flags,
.product-feed .product-feed__sale {
  width: 3.75rem;
}

.product-hero .product-feed__flags,
.collab-product-img .product-feed__flags {
  width: 5rem;
}

.product-feed__img-outer {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: 15.625rem;
}

.product-feed__img {
  position: relative;
  z-index: 1;
  display: block;
  height: auto;
  transition: transform 0.25s ease-in;
}

.page-template-tpl-shop .product-feed__img {
  width: 8.5rem;
}

.page-template-tpl-merch .product-feed__img {
  width: 10rem;
}

.product-feed__text {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 0 calc(var(--btn-height) + var(--padding-md));
}
.product-feed__text .btn, .product-feed__text .filter-action {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.product-feed__info {
  flex: 1;
  position: relative;
  display: grid;
  align-content: space-between;
  margin: 0 0 var(--padding-md);
  padding: 0 0 var(--padding-md);
}
.product-feed__info:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 40%;
  height: 1px;
  background-color: var(--color-grey);
}

.title + .product-feed__details, .woocommerce-cart .cart-empty + .product-feed__details, .order-total th + .product-feed__details, .std-content h4 + .product-feed__details, .std-content h3 + .product-feed__details, .std-content h2 + .product-feed__details, .product-attr + .product-feed__details, .woocommerce-checkout h2 + .product-feed__details, .woocommerce-checkout .wc-block-components-totals-footer-item .wc-block-components-totals-item__label + .product-feed__details {
  margin: 0.5rem 0 0;
}

.product-feed__detail {
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-xxs);
  line-height: 1;
  text-transform: uppercase;
}

.product-feed__characteristics {
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-xxs);
  line-height: 1;
  text-transform: uppercase;
}

.product-feed__details,
.product-feed__characteristics {
  opacity: 0.75;
}

.product-feed__price {
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 0.25rem;
}
.product-feed__price span {
  display: block;
  font-family: var(--loos-compressed);
  letter-spacing: var(--loos-kerning);
  text-transform: uppercase;
}
.product-feed__price .previous-price {
  font-size: 75%;
  color: var(--color-grey);
  text-decoration: line-through;
}
.product-feed__price .sale-price {
  color: var(--color-error);
}
.product-feed__price .normal-price {
  color: var(--color-dark-peak);
}

@media (min-width: 450px) {
  .similar-beers .product-feed,
  .shop .product-feed {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .similar-beers .product-feed,
  .shop .product-feed {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--padding-xxl) var(--padding-lg);
  }
}

.promo-rows {
  margin: 0 0 var(--padding-xxl);
  background-color: white;
}

.promo-row {
  display: grid;
}
@media (min-width: 768px) {
  .promo-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .promo-row__section {
    display: flex;
  }
}

@media (min-width: 768px) {
  .promo-row__section--feed {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .promo-row:nth-of-type(odd) .promo-row__section--feed {
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .promo-row:nth-of-type(even) .promo-row__section--feed {
    order: 2;
  }
}

.promo-row__products {
  padding: var(--padding-xl) var(--padding-md);
}
@media (min-width: 768px) {
  .promo-row__products {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1536px) {
  .promo-row__products {
    width: var(--mq-sm);
  }
}

.promo-row__cta {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.promo-row__img-outer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.promo-row__img-outer img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.promo-row__img-outer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.promo-row__text {
  position: relative;
  z-index: 2;
  display: grid;
  place-items: center;
  align-content: center;
  gap: var(--padding-lg);
  width: 100%;
  height: 100%;
  padding: var(--padding-xl);
  text-align: center;
}
@media (min-width: 768px) {
  .promo-row__text {
    padding: 6.25rem var(--padding-xl) calc(var(--btn-height) + var(--padding-lg));
  }
}
@media (min-width: 1280px) {
  .promo-row__text {
    width: 75%;
  }
}
@media (min-width: 768px) {
  .promo-row__text .stroke-btn {
    position: absolute;
    bottom: var(--padding-lg);
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }
}
.promo-row__text h2 {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xl);
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);
  font-weight: var(--font-weight-bold);
}
.promo-row__text h2 i {
  font-family: var(--loos-compressed);
  font-style: normal;
  font-weight: normal;
}

.promo-row__cta--dark .promo-row__img-outer:after {
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.95;
}
.promo-row__cta--dark .promo-row__text {
  color: white;
}
.promo-row__cta--dark .stroke-btn {
  color: white;
}
.promo-row__cta--dark .stroke-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.promo-row__cta--light .promo-row__img-outer:after {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 100%);
}
.promo-row__cta--light .stroke-btn {
  color: var(--color-dark-peak);
}
.promo-row__cta--light .stroke-btn:hover {
  background-color: rgba(50, 65, 66, 0.1);
}

.cta-grid {
  padding: 0 0 var(--padding-xxl);
}
@media (min-width: 768px) {
  .cta-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.cta-grid__col:first-child {
  background-color: var(--color-white-fade-80);
}
@media (min-width: 768px) {
  .cta-grid__col:first-child {
    display: flex;
    flex-direction: column;
  }
}

.cta-grid__col:last-child {
  background-color: white;
}

.untapped-cta,
.trade-cta {
  display: grid;
  justify-items: center;
  align-items: center;
  gap: var(--padding-lg);
  text-align: center;
  text-decoration: none;
}
.untapped-cta:hover,
.trade-cta:hover {
  text-decoration: underline;
}

.untapped-cta {
  padding: var(--padding-xxl) var(--padding-xl);
  background-color: var(--color-dark-peak);
  color: white;
}

.trade-cta {
  padding: var(--padding-xl);
  color: var(--color-dark-peak);
}
@media (min-width: 768px) {
  .trade-cta {
    flex: 1;
    align-content: center;
  }
}

.untapped-cta__img,
.trade-cta__img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .untapped-cta__img {
    width: 50%;
  }
}

.untapped-cta__text,
.trade-cta__text {
  padding: 0 15%;
}
.untapped-cta__text b, .untapped-cta__text strong,
.trade-cta__text b,
.trade-cta__text strong {
  font-weight: var(--font-weight-bold);
}

.map-cta__map {
  display: none;
}
@media (min-width: 768px) {
  .map-cta__map {
    display: block;
  }
}

.map-cta__img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
}

.map-cta__cta {
  display: grid;
  justify-items: center;
  gap: var(--padding-lg);
  padding: var(--padding-xl);
}
@media (min-width: 768px) {
  .map-cta__cta {
    justify-items: start;
  }
}
@media (min-width: 1280px) {
  .map-cta__cta {
    justify-items: start;
    grid-template-columns: 2fr 1fr;
  }
}

.ctas-outer {
  padding: var(--padding-xl) 0;
  background-color: var(--color-light-peak);
}

.ctas {
  display: grid;
}
@media (min-width: 768px) {
  .ctas {
    grid-template-columns: repeat(2, 1fr);
  }
}
.ctas .trade-cta {
  background-color: white;
}

.product-bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
}
.product-bg:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}

.product-bg__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
  object-position: top center;
  opacity: 0.6;
  transition: all 0.5s ease-out;
}
.product-bg__img.has-faded {
  opacity: 1;
  transition: all 0.5s ease-in;
}

.product-content {
  display: grid;
  gap: var(--padding-xl);
  position: relative;
  z-index: 2;
  padding: var(--paint-daub-padding) 0 0;
}

.product-hero {
  display: grid;
  align-items: start;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .product-hero {
    grid-template-columns: 1fr 3fr;
  }
}
@media (min-width: 1280px) {
  .product-hero {
    grid-template-columns: 1fr 1.5fr;
  }
}

.product-img-outer {
  position: relative;
  display: grid;
  place-items: center;
}

.product-img {
  position: relative;
  z-index: 1;
  display: block;
  width: auto;
  height: clamp(17.5rem, 10.8333rem + 33.3333vw, 37.5rem); /* 280px @ 320px > 600px @ 1280px */
}

.product-slider-nav {
  display: flex;
  justify-content: center;
  gap: 0.3125rem;
  padding: var(--padding-md) 0;
}

.product-slider-nav__btn {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  background-color: var(--color-cavern-blue);
  border: none;
}
.product-slider-nav__btn:hover, .product-slider-nav__btn.tns-nav-active {
  background-color: var(--color-dark-peak);
}

.product-desc {
  display: grid;
  width: 100%;
  justify-items: start;
}

.product-info {
  display: grid;
  gap: var(--padding-xl);
  padding: var(--padding-xl) 0 0;
  border-top: 1px solid var(--color-grey-light);
}
@media (min-width: 768px) {
  .product-info {
    padding: var(--padding-lg);
    background-color: rgba(255, 255, 255, 0.67);
    border: none;
  }
}
@media (min-width: 1280px) {
  .product-info {
    padding: var(--padding-xl);
  }
}

.product-variants {
  padding: var(--padding-md);
  background-color: var(--color-light-peak);
}
@media (min-width: 768px) {
  .product-variants {
    background-color: white;
  }
}

.variant,
.simple {
  width: 100%;
  border-collapse: collapse;
}
.variant tr,
.simple tr {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .variant tr:not(:last-child) td[data-title="Pack size"],
  .variant tr:not(:last-child) td[data-title=Price],
  .variant tr:not(:last-child) td[data-title=quantity],
  .simple tr:not(:last-child) td[data-title="Pack size"],
  .simple tr:not(:last-child) td[data-title=Price],
  .simple tr:not(:last-child) td[data-title=quantity] {
    padding: 0 0 var(--padding-lg);
  }
}
@media (min-width: 1024px) {
  .variant tr:not(:last-child) td[data-title=Action],
  .simple tr:not(:last-child) td[data-title=Action] {
    padding: 0 0 var(--padding-lg) var(--padding-lg);
  }
}
.variant tr:last-child td[data-title=Action],
.simple tr:last-child td[data-title=Action] {
  padding: var(--padding-md) 0 0;
}
@media (min-width: 1024px) {
  .variant tr:last-child td[data-title=Action],
  .simple tr:last-child td[data-title=Action] {
    padding: 0 0 0 var(--padding-lg);
  }
}
.variant td[data-title="Pack size"],
.variant td[data-title=Price],
.variant td[data-title=quantity],
.variant td[data-title=Action],
.simple td[data-title="Pack size"],
.simple td[data-title=Price],
.simple td[data-title=quantity],
.simple td[data-title=Action] {
  display: block;
}
.variant td[data-title="Pack size"],
.variant td[data-title=quantity],
.simple td[data-title="Pack size"],
.simple td[data-title=quantity] {
  width: 33.333%;
}
@media (min-width: 1024px) {
  .variant td[data-title="Pack size"],
  .variant td[data-title=quantity],
  .simple td[data-title="Pack size"],
  .simple td[data-title=quantity] {
    width: 20%;
  }
}
.variant td[data-title=Price],
.simple td[data-title=Price] {
  width: 33.333%;
}
@media (min-width: 1024px) {
  .variant td[data-title=Price],
  .simple td[data-title=Price] {
    width: 25%;
  }
}
.variant td[data-title=Price] > span,
.simple td[data-title=Price] > span {
  display: block;
}
.variant td[data-title=Price] .per-unit,
.simple td[data-title=Price] .per-unit {
  font-size: 60%;
}
.variant td[data-title=Action],
.simple td[data-title=Action] {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--padding-md) 0 var(--padding-lg);
}
@media (min-width: 1024px) {
  .variant td[data-title=Action],
  .simple td[data-title=Action] {
    width: 35%;
    justify-content: flex-start;
    padding: 0 0 0 var(--padding-md);
  }
}
.variant td[data-title=Action] button,
.simple td[data-title=Action] button {
  width: 100%;
}
@media (min-width: 1024px) {
  .variant td[data-title=Action] button,
  .simple td[data-title=Action] button {
    width: auto;
  }
}

td[data-title="Pack size"],
td[data-title=Price],
td[data-title=Action] > button {
  font-family: var(--loos-compressed);
  font-size: var(--font-size-sm);
  letter-spacing: var(--loos-kerning);
  text-transform: uppercase;
}

td[data-title=Price] {
  color: var(--color-dark-peak);
}
td[data-title=Price] del {
  padding: 0 0.25rem 0 0;
  font-size: 75%;
  color: var(--color-grey);
}
td[data-title=Price] ins {
  color: var(--color-error);
  text-decoration: none;
}

td[data-title=quantity] > div {
  display: flex;
}

td[data-title=Action] {
  width: 35%;
}

.qty-count {
  display: block;
  width: var(--btn-height);
  height: var(--btn-height);
  background: white;
  border: none;
  font-size: var(--font-size-md);
  text-align: center;
  color: var(--color-dark-peak);
  line-height: 2.5rem;
}
@media (min-width: 768px) {
  .qty-count {
    background: var(--color-light-peak);
  }
}
.qty-count:hover, .qty-count:focus {
  outline: none;
  color: var(--color-dark-peak-hover);
}

.input-text {
  width: var(--btn-height);
  height: var(--btn-height);
  color: var(--color-cavern-blue);
  text-align: center;
  border: 1px solid var(--color-cavern-blue);
}
.input-text:focus {
  outline: none;
  border: 3px solid var(--color-blue-john);
}

.product-variants .input-text,
.product-quantity .input-text {
  font-family: var(--loos-compressed);
  letter-spacing: var(--loos-kerning);
  text-transform: uppercase;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

td[data-title=Action] > button {
  position: relative;
  display: inline-block;
  height: var(--btn-height);
  padding: 0 var(--padding-md);
  font-family: var(--loos-compressed);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: var(--color-dark-peak);
}
td[data-title=Action] > button:hover {
  background-color: var(--color-dark-peak-hover);
}

td[data-title=Action] > button:disabled {
  cursor: not-allowed;
  background-color: #F9E6E2;
  color: #D35031;
}

.spinner-wrap {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.pvt-icon-spinner {
  display: block;
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, rgba(0, 0, 0, 0)) 0/33.3333333333% 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
  opacity: 0.5;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
.product-details {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: var(--padding-xl);
}

.product-details-outer .product-details__block {
  display: grid;
  gap: var(--padding-sm);
  width: 100%;
}
@media (min-width: 640px) {
  .product-details-outer .product-details__block:nth-child(1) {
    width: calc(50% - var(--padding-xl));
  }
}
@media (min-width: 1024px) {
  .product-details-outer .product-details__block:nth-child(1) {
    width: calc(35% - var(--padding-xl));
  }
}
@media (min-width: 640px) {
  .product-details-outer .product-details__block:nth-child(2) {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .product-details-outer .product-details__block:nth-child(2) {
    width: 30%;
  }
}
@media (min-width: 1024px) {
  .product-details-outer .product-details__block:nth-child(3) {
    width: calc(35% - var(--padding-xl));
  }
}

.product-taste-ratings {
  display: grid;
  gap: var(--padding-sm);
  padding: 0 0 var(--padding-sm);
  border-bottom: 1px solid var(--color-grey);
}

.product-taste-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-taste-rating__label {
  font-family: var(--loos-extended);
  text-transform: uppercase;
}

.product-taste-rating__score {
  display: flex;
  gap: 5px;
}

.product-taste-rating__pill {
  display: block;
  width: 3px;
  height: 15px;
  background-color: var(--color-dark-peak);
  opacity: 0.25;
}

.product-taste-rating__score--1 .product-taste-rating__pill:nth-child(-n+1) {
  opacity: 1;
}

.product-taste-rating__score--2 .product-taste-rating__pill:nth-child(-n+2) {
  opacity: 1;
}

.product-taste-rating__score--3 .product-taste-rating__pill:nth-child(-n+3) {
  opacity: 1;
}

.product-taste-rating__score--4 .product-taste-rating__pill:nth-child(-n+4) {
  opacity: 1;
}

.product-taste-rating__score--5 .product-taste-rating__pill:nth-child(-n+5) {
  opacity: 1;
}

.product-taste-rating__score--6 .product-taste-rating__pill:nth-child(-n+6) {
  opacity: 1;
}

.product-taste-rating__score--7 .product-taste-rating__pill:nth-child(-n+7) {
  opacity: 1;
}

.product-taste-rating__score--8 .product-taste-rating__pill:nth-child(-n+8) {
  opacity: 1;
}

.product-taste-rating__score--9 .product-taste-rating__pill:nth-child(-n+9) {
  opacity: 1;
}

.product-taste-rating__score--10 .product-taste-rating__pill:nth-child(-n+10) {
  opacity: 1;
}

.product-dietary-info {
  text-align: center;
}
.product-dietary-info p {
  font-size: var(--font-size-xs);
  text-align: center;
}
.product-dietary-info strong {
  font-weight: var(--font-weight-bold);
}

.product-color {
  position: relative;
  display: grid;
  place-items: center;
  padding: var(--padding-lg);
  text-align: center;
}
.product-color p {
  position: relative;
  z-index: 2;
  font-family: var(--loos-extended);
  font-size: var(--font-size-lg);
  text-transform: uppercase;
  letter-spacing: var(--loos-kerning);
  line-height: var(--loos-line-height);
  color: white;
}
.product-color p i {
  font-family: var(--loos-compressed);
  font-style: normal;
}
.product-color:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.product-details-outer .product-color {
  width: 100%;
  height: 9.375rem;
}
@media (min-width: 768px) {
  .product-details-outer .product-color {
    height: auto;
    aspect-ratio: 3/2;
  }
}

.product-color--1 {
  background-color: #fde294;
}

.product-color--2 {
  background-color: #fbd375;
}

.product-color--3 {
  background-color: #fbc35b;
}

.product-color--4 {
  background-color: #f9b949;
}

.product-color--5 {
  background-color: #f3a936;
}

.product-color--6 {
  background-color: #f09e29;
}

.product-color--7 {
  background-color: #e89427;
}

.product-color--8 {
  background-color: #df8624;
}

.product-color--9 {
  background-color: #dc7e23;
}

.product-color--10 {
  background-color: #d27321;
}

.product-color--11 {
  background-color: #ca691f;
}

.product-color--12 {
  background-color: #c1621d;
}

.product-color--13 {
  background-color: #bb5a1c;
}

.product-color--14 {
  background-color: #b4531a;
}

.product-color--15 {
  background-color: #aa4a18;
}

.product-color--16 {
  background-color: #a44717;
}

.product-color--17 {
  background-color: #9f4016;
}

.product-color--18 {
  background-color: #953915;
}

.product-color--19 {
  background-color: #8f3313;
}

.product-color--20 {
  background-color: #893012;
}

.product-color--21 {
  background-color: #832b11;
}

.product-color--22 {
  background-color: #7e2911;
}

.product-color--23 {
  background-color: #72240f;
}

.product-color--24 {
  background-color: #711f0f;
}

.product-color--25 {
  background-color: #6d1b0f;
}

.product-color--26 {
  background-color: #651b0d;
}

.product-color--27 {
  background-color: #5c190b;
}

.product-color--28 {
  background-color: #5d130e;
}

.product-color--29 {
  background-color: #55120a;
}

.product-color--30 {
  background-color: #4a1009;
}

.product-color--31 {
  background-color: #4a100b;
}

.product-color--32 {
  background-color: #510f0b;
}

.product-color--33 {
  background-color: #450f0e;
}

.product-color--34 {
  background-color: #400c0c;
}

.product-color--35 {
  background-color: #3a0c0c;
}

.product-color--36 {
  background-color: #3a0e0e;
}

.product-color--37 {
  background-color: #340e0d;
}

.product-color--38 {
  background-color: #310c0d;
}

.product-color--39 {
  background-color: #310d0f;
}

.product-color--40 {
  background-color: #2e0d0e;
}

.product-recommendations {
  display: grid;
  gap: var(--padding-xs);
}

.product-recommendation {
  display: flex;
  border: 1px solid var(--color-dark-peak);
  background-color: white;
}
@media (min-width: 1280px) {
  .product-recommendation {
    border: none;
  }
}

.product-recommendation__label-cell {
  display: grid;
  flex-shrink: 0;
  width: 30%;
  padding: var(--padding-sm) var(--padding-xs);
  background-color: var(--color-dark-peak);
  text-align: center;
}

.product-recommendation__label {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  color: white;
}

.product-recommendation__value-cell {
  display: grid;
  align-items: center;
  flex: 1;
  padding: var(--padding-sm);
}

.product-recommendation__value {
  font-size: var(--font-size-xs);
}

.product-gallery {
  display: grid;
}
@media (min-width: 450px) {
  .product-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

.product-gallery__img {
  position: relative;
  aspect-ratio: 1/1;
}
.product-gallery__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 768px) {
  .merchandise .product-hero,
  .gift-cards .product-hero {
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
  }
}
.merchandise .product-img-outer,
.gift-cards .product-img-outer {
  background-color: white;
}
.merchandise .product-img,
.gift-cards .product-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.product-variants--dropdowns .product_title {
  display: none !important;
}
.product-variants--dropdowns .price {
  display: block;
  border-bottom: 1px solid var(--color-grey-light);
  padding: 0 0 var(--padding-md);
  margin: 0 0 var(--padding-md);
}
.product-variants--dropdowns .price,
.product-variants--dropdowns .variations label {
  font-family: var(--loos-compressed);
  font-size: var(--font-size-sm);
  letter-spacing: var(--loos-kerning);
  text-transform: uppercase;
}
.product-variants--dropdowns .variations label {
  padding: 0 1.25rem 0 0;
}
.product-variants--dropdowns .variations th,
.product-variants--dropdowns .variations td {
  padding: 0 var(--padding-xs) var(--padding-xs) 0;
}
.product-variants--dropdowns .value select {
  width: 100%;
  height: 2.5rem;
  padding: 0 var(--padding-xs);
  border: 1px solid var(--color-dark-peak);
  font-family: var(--loos-normal);
  font-size: var(--font-size-xs);
}
.product-variants--dropdowns .value select:focus {
  outline: none;
  border: 3px solid var(--color-blue-john);
}
.product-variants--dropdowns .single_add_to_cart_button {
  position: relative;
  display: inline-block;
  height: var(--btn-height);
  padding: 0 var(--padding-md);
  margin: var(--padding-md) 0 0;
  font-family: var(--loos-compressed);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: var(--color-dark-peak);
}
.product-variants--dropdowns .single_add_to_cart_button:hover {
  background-color: var(--color-dark-peak-hover);
}
.product-variants--dropdowns .stock {
  margin: var(--padding-md) 0 0;
  font-family: var(--loos-compressed);
  font-size: var(--font-size-sm);
  letter-spacing: var(--loos-kerning);
  text-transform: uppercase;
}
.product-variants--dropdowns .out-of-stock {
  color: var(--color-error);
}

.gift-cards .variations {
  margin: 0 0 var(--padding-md);
}
.gift-cards .woocommerce_gc_giftcard_form {
  display: grid;
  gap: var(--padding-md);
}
.gift-cards abbr {
  text-decoration: none;
}
.gift-cards textarea.input-text {
  width: 100%;
  height: 6.25rem;
  padding: var(--padding-xs);
  text-align: left;
}
.gift-cards .text-btn--clear {
  display: none;
}
.gift-cards .woocommerce_gc_giftcard_form {
  margin: 0;
}
.gift-cards .woocommerce_gc_giftcard_form .wc_gc_field {
  padding: 0;
  margin: 0;
}
.gift-cards input[type=text].datepicker {
  background: url("svg/glyph-date.svg") center right 0.5rem no-repeat;
}
.gift-cards .product-img-outer {
  background: none;
}
.gift-cards .woocommerce-variation-price {
  display: none !important;
}
.gift-cards .product-variants .input-text {
  font-family: var(--loos-normal);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
  color: var(--color-dark-peak);
  text-transform: none;
  letter-spacing: normal;
}
.std-content {
  width: 100%;
}
.std-content > *:not(h2, h3, h4, h5, h6) + * {
  margin: var(--padding-md) 0 0;
}
.std-content h2:not(:first-child), .std-content h3:not(:first-child), .std-content h4:not(:first-child), .std-content h5:not(:first-child), .std-content h6:not(:first-child) {
  margin: var(--padding-lg) 0 var(--padding-md);
}
.std-content h2:first-child, .std-content h3:first-child, .std-content h4:first-child, .std-content h5:first-child, .std-content h6:first-child {
  margin: 0 0 var(--padding-md);
}
.std-content h4 {
  font-size: var(--font-size-xs);
}
.std-content h5, .std-content h6 {
  font-family: var(--gravesend-sans);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xs);
}
.std-content p a, .std-content ul a, .std-content ol a {
  text-decoration: underline;
  color: inherit;
}
.std-content p a:hover, .std-content ul a:hover, .std-content ol a:hover {
  opacity: 0.5;
}
.std-content p > strong, .std-content ul > strong, .std-content ol > strong {
  font-weight: var(--font-weight-bold);
}
.std-content p > em, .std-content ul > em, .std-content ol > em {
  font-style: italic;
}
.std-content p {
  line-height: var(--line-height-md);
}
.std-content sup {
  font-size: 75%;
}
.std-content ul {
  list-style-type: disc;
  padding-left: var(--padding-sm);
}
.std-content ol {
  list-style-type: decimal;
  padding-left: var(--padding-sm);
  counter-reset: item;
}
.std-content ul ul {
  margin: 0.625rem 0 0 1.875rem;
  list-style-type: circle;
}
.std-content li {
  line-height: var(--line-height-lg);
}
.std-content img {
  display: block;
  width: 100%;
  height: auto;
  margin: var(--padding-md) auto;
}
.std-content blockquote {
  display: block;
  padding: var(--padding-xl) var(--padding-md);
  margin: var(--padding-xl) 0;
  border-top: 1px solid var(--color-grey-light);
  border-bottom: 1px solid var(--color-grey-light);
  text-align: center;
}
.std-content blockquote p {
  font-family: var(--loos-extended);
  font-size: var(--font-size-md);
  line-height: var(--line-height-sm);
  font-style: normal;
  color: var(--color-dark-peak);
}
.std-content blockquote p:before {
  content: open-quote;
}
.std-content blockquote p:after {
  content: close-quote;
}
.std-content address {
  font-style: normal;
}

.std-content img.alignleft,
.std-content img.alignright {
  width: 50%;
  margin: var(--padding-md) auto;
}
@media (min-width: 640px) {
  .std-content img.alignleft,
  .std-content img.alignright {
    width: 20%;
    clear: both;
    margin-top: 0.5rem;
    margin-bottom: var(--padding-sm);
  }
}
@media (min-width: 1280px) {
  .std-content img.alignleft,
  .std-content img.alignright {
    width: 25%;
  }
}
@media (min-width: 640px) {
  .std-content img.alignleft {
    float: left;
    margin-right: var(--padding-md);
  }
}
@media (min-width: 640px) {
  .std-content img.alignright {
    float: right;
    margin-left: var(--padding-md);
  }
}
.std-content img.alignnone {
  clear: both;
  margin: var(--padding-lg) auto;
}
.std-content .wp-caption {
  width: 100% !important;
  height: auto;
  margin: var(--padding-lg) auto;
}
.std-content .wp-caption img {
  margin: 0;
}
.std-content p.wp-caption-text {
  margin: 0 auto;
  padding: 1rem;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-sm);
  text-align: center;
  color: var(--color-blue-dark);
  background-color: var(--color-light-peak);
}

.std-content iframe,
.video-embed iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.similar-beers {
  display: grid;
  gap: var(--padding-md);
  padding: 0 0 var(--padding-xl);
}

.similar-beers__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--padding-xs);
  padding: 0 0 var(--padding-md);
  border-bottom: 1px solid var(--color-grey);
}

.similar-beers__header--centered {
  display: block;
  text-align: center;
}

.pre-footer-cta {
  position: relative;
  overflow: hidden;
  background-color: black;
}
.pre-footer-cta:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
  transition: all 0.5s ease-out;
}
.pre-footer-cta .inner {
  position: relative;
  z-index: 3;
}

.pre-footer-cta__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 1;
  transition: all 0.5s ease-out;
}
@media (min-width: 640px) {
  .pre-footer-cta__img {
    width: 100%;
    height: auto;
  }
}

.pre-footer-cta__content {
  display: grid;
  place-items: center;
  gap: var(--padding-lg);
  padding: clamp(5rem, 2.5rem + 12.5vw, 12.5rem) 0;
}

.pre-footer-cta__title {
  font-family: var(--loos-extended);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  text-align: center;
  color: white;
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);
}
.pre-footer-cta__title i {
  font-family: var(--loos-compressed);
  font-style: normal;
}

.pre-footer-cta__text {
  text-align: center;
  color: white;
}

.single-product .pre-footer-cta__title {
  color: var(--color-dark-peak);
}
.single-product .pre-footer-cta__text {
  color: var(--color-dark-peak);
}
.single-product .pre-footer-cta {
  background-color: white;
}
.single-product .pre-footer-cta:after {
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}

.email-signup {
  width: 100%;
  padding: var(--padding-lg) 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.email-signup form {
  display: grid;
  gap: var(--padding-md);
}

.email-signup__row {
  display: flex;
  justify-content: center;
}
.email-signup__row p {
  text-align: center;
  font-size: var(--font-size-xxs);
  line-height: var(--line-height-md);
  color: white;
}

.email-signup__fields {
  display: grid;
  gap: var(--padding-lg);
  width: 100%;
}
@media (min-width: 768px) {
  .email-signup__fields {
    grid-template-columns: repeat(3, 1fr);
  }
}

.email-signup__field {
  flex: 1;
  display: grid;
  gap: var(--padding-sm);
}
.email-signup__field label {
  font-family: var(--loos-condensed);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);
  color: white;
}
.email-signup__field input[type=text],
.email-signup__field input[type=email] {
  width: 100%;
  height: 3.75rem;
  padding: 0 0.625rem;
  font-family: inherit;
  border: none;
}
.email-signup__field input[type=text]:focus,
.email-signup__field input[type=email]:focus {
  outline: none;
  border: 3px solid var(--color-blue-john);
}

.email-signup__validation {
  display: block;
  width: 100%;
  padding: 1rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-grey-dark);
}

.email-signup__validation--error {
  margin: var(--padding-lg) 0 0;
  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.email-signup__validation--success {
  margin: var(--padding-lg) 0 0;
  border: 1px solid #80A06E;
  background-color: #EDF1EA;
}

.pre-footer-cta.is-visible:after {
  opacity: 0.5;
  transition: all 0.5s ease-in;
}
.pre-footer-cta.is-visible .pre-footer-cta__img {
  opacity: 0.5;
  transition: all 0.5s ease-in;
}

.collab-hero {
  position: relative;
  padding: clamp(5rem, 3.125rem + 9.375vw, 12.5rem) 0 clamp(2.5rem, 1.5625rem + 4.6875vw, 6.25rem);
}
.collab-hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 50%;
  height: 100%;
}

.collab-hero__content {
  position: relative;
  z-index: 2;
  display: grid;
  gap: var(--padding-lg);
  padding: var(--padding-xl) 0 0;
}
.collab-hero__content > .inner {
  position: relative;
  z-index: 2;
}

.collab-hero-imgs {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);
}
@media (min-width: 640px) {
  .collab-hero-imgs {
    gap: 0;
  }
}

.collab-hero-sticker {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(35%, -50%);
  z-index: 2;
  display: block;
  width: min(9.375rem, 25%);
  height: auto;
}

.collab-hero-product {
  position: relative;
  z-index: 1;
  display: grid;
  place-items: center;
  width: 75%;
}
@media (min-width: 640px) {
  .collab-hero-product {
    order: 2;
    width: 15rem;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .collab-hero-product {
    width: 17.5rem;
  }
}
@media (min-width: 1280px) {
  .collab-hero-product {
    width: 28.125rem;
  }
}
.collab-hero-product svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
}
.collab-hero-product img {
  position: relative;
  z-index: 2;
  width: 80%;
}

.collab-hero-logo {
  width: min(250px, 30%);
  opacity: 0.25;
}
.collab-hero-logo svg,
.collab-hero-logo img {
  display: block;
  width: 100%;
}

@media (min-width: 640px) {
  .collab-hero-logo--buxton {
    order: 1;
  }
}

@media (min-width: 640px) {
  .collab-hero-logo--collab {
    order: 3;
  }
}

.collab-hero-text {
  display: grid;
  gap: var(--padding-md);
  justify-items: center;
  text-align: center;
}

.collab-product-info {
  display: grid;
  align-items: start;
}
@media (min-width: 768px) {
  .collab-product-info {
    grid-template-columns: 1fr 1.5fr;
    gap: var(--padding-lg);
  }
}

@media (min-width: 768px) {
  .collab-product-info__soon {
    align-items: center;
  }
}

.collab-product-img {
  position: relative;
  display: grid;
  place-items: center;
}

.collab-product-img__product {
  position: relative;
  z-index: 1;
  display: block;
  width: auto;
  height: clamp(17.5rem, 10.8333rem + 33.3333vw, 37.5rem); /* 280px @ 320px > 600px @ 1280px */
}

.collab-product-img__sticker {
  position: absolute;
  transform: scale(0.5);
  z-index: 2;
  display: block;
  width: min(6.25rem, 20%);
  height: auto;
  transition: all 0.25s ease-in;
  opacity: 0;
  transform: scale(0.75);
}
.collab-product-img__sticker.is-visible {
  transition: all 0.25s ease-out;
  opacity: 1;
  transform: scale(1);
}

.collab-hero-imgs .collab-product-img__sticker {
  top: -5%;
  left: 57.5%;
}

.collab-product-img .collab-product-img__sticker {
  top: -5%;
  right: 57.5%;
}

.collab-product-content {
  display: grid;
  gap: var(--padding-xl);
  justify-items: start;
  padding: var(--padding-xl) var(--padding-lg);
  background-color: var(--color-light-peak);
}
.collab-product-content .product-details__block {
  display: grid;
  gap: var(--padding-sm);
  width: 100%;
}
@media (min-width: 1024px) {
  .collab-product-content .product-details__block:nth-child(1) {
    width: calc(50% - var(--padding-xl) / 2);
  }
}
@media (min-width: 1024px) {
  .collab-product-content .product-details__block:nth-child(2) {
    width: calc(50% - var(--padding-xl) / 2);
  }
}
.collab-product-content .product-color {
  aspect-ratio: 2/1;
}

.coming-soon-flag {
  display: block;
  padding: var(--padding-xs) var(--padding-md);
  margin: var(--padding-sm) 0 0;
  background-color: red;
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  color: white;
}

.coming-soon-cta {
  display: grid;
  justify-items: start;
  gap: var(--padding-lg);
  padding: var(--padding-xl);
}

.coming-soon-cta__title {
  font-family: var(--loos-extended);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);
}
.coming-soon-cta__title i {
  font-family: var(--loos-compressed);
  font-style: normal;
}

@media (min-width: 768px) {
  .collab-hero-product img {
    transition: all 0.5s ease-out;
    transform-origin: bottom center;
  }
  .collab-hero-product svg,
  .collab-hero-sticker,
  .collab-hero-logo--buxton,
  .collab-hero-logo--collab {
    transition: all 0.25s ease-out;
  }
  .collab-hero-imgs.has-faded .collab-hero-product img {
    opacity: 0;
    transform: translateY(-5%);
    transition: all 0.5s ease-in;
  }
  .collab-hero-imgs.has-faded .collab-hero-product svg,
  .collab-hero-imgs.has-faded .collab-hero-sticker,
  .collab-hero-imgs.has-faded .collab-hero-logo--buxton,
  .collab-hero-imgs.has-faded .collab-hero-logo--collab {
    transition: all 0.25s ease-in;
  }
  .collab-hero-imgs.has-faded .collab-hero-product svg {
    opacity: 0;
    transform: translate(-50%, -50%) scale(3);
  }
  .collab-hero-imgs.has-faded .collab-hero-logo--buxton {
    transform: translateX(50%);
  }
  .collab-hero-imgs.has-faded .collab-hero-logo--collab {
    transform: translateX(-50%);
  }
  .collab-hero-imgs.has-faded .collab-hero-sticker {
    opacity: 0;
  }
}
.collab-hero--dark {
  background-color: black;
}
.collab-hero--dark:after {
  background-color: rgba(255, 255, 255, 0.15);
}
.collab-hero--dark .collab-hero-logo > svg {
  fill: white;
}
.collab-hero--dark .stroke-btn {
  color: white;
}
.collab-hero--dark .stroke-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.collab-hero--light {
  background-color: var(--color-light-peak);
}
.collab-hero--light:after {
  background-color: white;
}
.collab-hero--light .stroke-btn {
  color: black;
}
.collab-hero--light .stroke-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.promo-text {
  padding: var(--padding-lg) 0;
}
.promo-text p {
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-md);
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .promo-text p {
    text-align: center;
  }
}

.promo-text.bg-dark-peak {
  background-color: var(--color-dark-peak);
}
.promo-text.bg-dark-peak p {
  color: white;
}

.rich-text-col.bg-dark-peak {
  background-color: var(--color-dark-peak);
}
.rich-text-col.bg-dark-peak p {
  color: white;
}

.text-img-inner.bg-dark-peak {
  padding: var(--padding-xl) 0;
  background-color: var(--color-dark-peak);
}
.text-img-inner.bg-dark-peak p {
  color: white;
}

.text-img {
  display: grid;
  gap: var(--padding-xxl);
}

.text-img__img-col__img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 640px) {
  .text-img-outer.is-odd .text-img {
    grid-template-columns: 2fr 1fr;
  }
}
@media (min-width: 1280px) {
  .text-img-outer.is-odd .text-img {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}

@media (min-width: 640px) {
  .text-img-outer.is-even .text-img {
    grid-template-columns: 1fr 2fr;
  }
}
@media (min-width: 1280px) {
  .text-img-outer.is-even .text-img {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}
.text-img-outer.is-even .text-img__text-col {
  order: 2;
}
.text-img-outer.is-even .text-img__img-col {
  order: 1;
}

.shop-outer {
  padding: var(--paint-daub-max-height) 0 var(--padding-xxl);
}

.shop {
  display: grid;
  gap: var(--padding-lg);
}

.shop-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 var(--padding-md);
  padding: 0 0 var(--padding-md);
  border-bottom: 1px solid var(--color-grey-light);
}
@media (min-width: 1280px) {
  .shop-header {
    flex-direction: column;
    align-items: start;
    gap: var(--padding-md);
  }
}

.filters-outer {
  display: none;
  z-index: 0;
  position: relative;
  top: 0;
  right: 0;
  z-index: 0;
  flex-direction: column;
  gap: var(--padding-md);
  width: 100%;
  height: 100%;
  padding: var(--padding-sm) 0 calc(var(--padding-xl) + var(--btn-height)) var(--padding-sm);
  overflow-y: scroll;
  background-color: white;
}
.filters-outer.is-open {
  display: flex;
  position: fixed;
  z-index: 10000;
}
@media (min-width: 768px) {
  .filters-outer {
    width: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 1280px) {
  .filters-outer {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    height: auto;
    padding: 0;
    overflow-y: visible;
    background: none;
    box-shadow: none;
  }
}

.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 1280px) {
  .filters-header {
    display: none;
  }
}

.filters-title {
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-sm);
  text-transform: uppercase;
}
@media (min-width: 1280px) {
  .filters-title {
    display: none;
  }
}

.filters-header__close {
  display: grid;
  place-items: center;
  width: var(--btn-height);
  height: var(--btn-height);
  background: none;
}
@media (min-width: 1280px) {
  .filters-header__close {
    display: none;
  }
}
.filters-header__close svg {
  width: 1rem;
  height: 1rem;
  fill: currentColor;
}

.open-filters,
.toggle-sort {
  align-items: center;
  gap: var(--padding-sm);
  height: var(--btn-height);
  padding: 0 var(--padding-sm);
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid;
  color: var(--color-dark-peak);
  background: none;
}
.open-filters svg,
.toggle-sort svg {
  width: 1rem;
  height: 1rem;
  fill: currentColor;
}

.open-filters {
  display: flex;
}
@media (min-width: 1280px) {
  .open-filters {
    display: none;
  }
}

.toggle-sort {
  display: none;
}
@media (min-width: 1280px) {
  .toggle-sort {
    display: flex;
  }
}
.toggle-sort:hover {
  background-color: var(--color-dark-peak-fade-10);
}
.toggle-sort.is-active {
  background-color: var(--color-dark-peak);
  color: white;
}

@media (min-width: 1280px) {
  .filters {
    display: flex;
    gap: var(--padding-md);
  }
}

.filter,
.sort-option {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);
  width: 100%;
  height: var(--btn-height);
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  background: none;
}
.filter:hover,
.sort-option:hover {
  color: var(--color-dark-peak-hover);
  text-decoration: underline;
}
.filter.mixitup-control-active .filter__check,
.sort-option.mixitup-control-active .filter__check {
  border-color: var(--color-dark-peak);
  background-color: var(--color-dark-peak);
}
.filter.mixitup-control-active .filter__check svg,
.sort-option.mixitup-control-active .filter__check svg {
  display: block;
  fill: white;
}

.filter__check {
  display: grid;
  place-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid var(--color-grey);
}
.filter__check svg {
  display: none;
  width: 0.75rem;
  height: 0.75rem;
}

@media (min-width: 1280px) {
  .sort {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;
    padding: var(--padding-md);
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
}
.sort.is-open {
  display: block;
}

.filter-actions {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  padding: var(--padding-sm);
  background-color: var(--color-light-peak);
}
@media (min-width: 768px) {
  .filter-actions {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .filter-actions {
    display: none;
  }
}

.filter-action {
  width: 100%;
}

.text-hero {
  padding: var(--paint-daub-max-height) 0 var(--padding-md);
  margin: 0 0 var(--padding-xl);
  border-bottom: 1px solid var(--color-grey-light);
}

.text-hero--contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);
  padding: var(--paint-daub-max-height) 0 0;
  border: none;
}

.text-hero--social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);
}

.text-hero__social {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-md);
}

.locations-outer {
  display: grid;
  gap: var(--padding-lg);
}

.locations {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 640px) {
  .locations {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .locations {
    grid-template-columns: repeat(3, 1fr);
  }
}

.location {
  background-color: var(--color-light-peak);
}
@media (min-width: 640px) {
  .location {
    display: flex;
    flex-direction: column;
  }
}

.map-cta .location {
  background: none;
  text-align: center;
}
@media (min-width: 768px) {
  .map-cta .location {
    text-align: left;
  }
}
.map-cta strong {
  font-weight: var(--font-weight-bold);
}

.location__img {
  aspect-ratio: 16/9;
  overflow: hidden;
}
.location__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.location__info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: var(--padding-sm);
  padding: var(--padding-lg) var(--padding-lg) calc(var(--btn-height) + var(--padding-lg) + var(--padding-sm));
}
@media (min-width: 768px) {
  .location__info {
    flex: 1;
  }
}
.location__info .btn, .location__info .filter-action {
  position: absolute;
  bottom: var(--padding-lg);
  left: var(--padding-lg);
  z-index: 2;
}

.basket {
  display: grid;
  gap: var(--padding-xl);
  margin: 0 0 var(--padding-xl);
}
@media (min-width: 768px) {
  .basket {
    grid-template-columns: 2fr 1fr;
  }
}

.product-table {
  display: grid;
  gap: var(--padding-md);
}
.product-table .cart_item {
  position: relative;
  min-height: 5rem;
  padding: 0 3.125rem 0 5rem;
}
@media (min-width: 1024px) {
  .product-table .cart_item {
    display: flex;
    padding: 0;
  }
}
.product-table .product-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
}
@media (min-width: 1024px) {
  .product-table .product-thumbnail {
    position: relative;
  }
}
.product-table .product-thumbnail img {
  display: block;
  width: auto;
  height: 5rem;
}
.product-table .group-1 {
  margin: 0 0 var(--padding-sm);
}
@media (min-width: 1024px) {
  .product-table .group-1 {
    display: flex;
    align-items: center;
    margin: 0;
    flex: 1;
  }
}
.product-table .group-2 {
  display: flex;
  gap: var(--padding-md);
  align-items: center;
}
.product-table .product-name {
  flex: 1;
}
.product-table .product-price {
  width: 9.375rem;
}
.product-table .product-price span {
  font-family: var(--loos-compressed);
  letter-spacing: var(--loos-kerning);
  text-transform: uppercase;
  color: var(--color-dark-peak);
}
.product-table .product-remove {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
@media (min-width: 1024px) {
  .product-table .product-remove {
    position: relative;
    top: 0;
    transform: none;
  }
}
.product-table .product-remove:hover {
  background-color: var(--color-light-peak);
}
.product-table .product-remove svg {
  width: 1rem;
  height: 1rem;
  fill: var(--color-dark-peak);
}

.cart-text {
  display: grid;
  gap: var(--padding-xs);
}

.cart-collaterals {
  padding: var(--padding-lg);
  background-color: var(--color-light-peak);
}

.cart_totals {
  display: grid;
  gap: var(--padding-lg);
}

.price-table .shop_table th {
  width: 40%;
  padding: 0 0 var(--padding-sm);
  vertical-align: top;
}
.price-table .shop_table td {
  width: 60%;
  padding: 0 0 var(--padding-sm) var(--padding-sm);
  vertical-align: top;
}
.price-table .shop_table tr:last-child th {
  padding: 0;
}
.price-table .shop_table tr:last-child td {
  padding: 0 0 0 var(--padding-sm);
}

.woocommerce-table--order-details.shop_table td {
  width: auto;
  padding: 0 0 var(--padding-sm);
}

.cart-subtotal th,
.cart-discount th,
.shipping th {
  font-weight: var(--font-weight-bold);
}

.cart-subtotal td,
.order-total td,
.cart-discount td,
.woocommerce-shipping-methods {
  font-family: var(--loos-compressed);
  letter-spacing: var(--loos-kerning);
  color: var(--color-dark-peak);
}

.cart-discount td a {
  padding: 0 0 0 0.625rem;
  color: var(--color-dark-peak);
}

.shipping th,
.shipping td {
  vertical-align: top;
}

.woocommerce-shipping-methods {
  margin: 0 0 var(--padding-sm);
}
.woocommerce-shipping-methods input[type=radio] {
  padding: 0;
  margin: 0 var(--padding-xs) 0 0;
}

.order-total {
  font-size: var(--font-size-md);
}

.order-total td {
  color: var(--color-dark-peak);
}

.wc-proceed-to-checkout {
  display: grid;
  gap: var(--padding-md);
  padding: var(--padding-lg) 0 0;
  border-top: 1px solid var(--color-grey);
}

.ssl-label {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);
}
.ssl-label svg {
  width: 1rem;
  height: 1rem;
  fill: var(--color-blue-john);
}

.actions {
  display: grid;
  gap: var(--padding-lg);
  padding: var(--padding-lg) 0 0;
  border-top: 1px solid var(--color-grey-light);
}
@media (min-width: 768px) {
  .actions {
    gap: var(--padding-xxl);
    grid-template-columns: 2fr 1fr;
  }
}

.coupon,
.update-basket {
  display: grid;
  gap: var(--padding-sm);
}

.coupon-form {
  display: flex;
  gap: var(--padding-md);
  width: 100%;
}
.coupon-form input[type=text] {
  flex: 1;
  text-align: left;
}

.wc-empty-cart-message {
  margin: 0 0 var(--padding-lg);
  text-align: center;
}

.return-to-shop {
  display: grid;
  justify-content: center;
}

.age-popup-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-light-peak);
}

.age-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
  padding: var(--padding-xl) var(--padding-lg);
  text-align: center;
  background-color: white;
}
.age-popup form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
}

.age-popup__logo {
  display: block;
  width: 6.25rem;
}
.age-popup__logo svg {
  fill: var(--color-dark-peak);
}
@media (min-width: 768px) {
  .age-popup__logo {
    width: 9.375rem;
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .age-popup__logo {
    width: 12.5rem;
    margin: 0;
  }
}

.dob-inputs {
  display: flex;
  gap: var(--padding-sm);
}

.dob-error {
  display: none;
  padding: var(--padding-md);
  border: 1px solid #D35031;
  background-color: #F9E6E2;
}
.dob-error p {
  font-weight: var(--font-weight-bold);
}
.dob-error.is-visible {
  display: block;
}

.age-popup-paint-daub svg {
  width: 100%;
  height: auto;
  fill: white;
}

.age-verify-cancel {
  cursor: pointer;
}

.news-feed {
  display: grid;
  gap: var(--padding-xl);
  margin: 0 0 var(--padding-xxl);
}
@media (min-width: 450px) {
  .news-feed {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .news-feed {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--padding-xl) var(--padding-lg);
  }
}

.news-feed__img-outer {
  display: block;
  position: relative;
  aspect-ratio: 16/9;
}

.news-feed__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-feed__text {
  display: grid;
  gap: var(--padding-sm);
  padding: var(--padding-md) var(--padding-lg) var(--padding-md) 0;
}

.page-template-tpl-my-account .woocommerce {
  margin: 0 0 var(--padding-xl);
}

.woocommerce-form-login,
.woocommerce-form-register,
.edit-account,
.edit-account fieldset,
.woocommerce-address-fields,
.woocommerce-address-fields__field-wrapper {
  display: grid;
  gap: var(--padding-md);
}

.woocommerce-register {
  margin: var(--padding-xl) 0 0;
  padding: var(--padding-xl) 0 0;
  border-top: 1px solid var(--color-grey-light);
}

.woocommerce-form-row,
.woocommerce .form-row {
  display: grid;
  gap: var(--padding-sm);
}
.woocommerce-form-row label,
.woocommerce .form-row label {
  display: block;
}
.woocommerce-form-row input[type=text],
.woocommerce-form-row input[type=password],
.woocommerce-form-row input[type=email],
.woocommerce-form-row input[type=tel],
.woocommerce .form-row input[type=text],
.woocommerce .form-row input[type=password],
.woocommerce .form-row input[type=email],
.woocommerce .form-row input[type=tel] {
  width: 100%;
  text-align: left;
}

.woocommerce-form__label-for-checkbox {
  display: flex;
  gap: var(--padding-xs);
  cursor: pointer;
}

.edit-account fieldset {
  display: grid;
  gap: var(--padding-md);
  padding: var(--padding-md) 0 0;
  border-top: 3px solid var(--color-grey-light);
}

.select2-container--default .select2-selection--single {
  display: flex;
  align-items: center;
  height: var(--btn-height);
  padding: 0 var(--padding-xs);
  border: 1px solid var(--color-dark-peak);
  border-radius: 0;
}
.select2-container--default .select2-selection--single:focus {
  outline: none;
  border: 3px solid var(--color-blue-john);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%);
}

.woocommerce-MyAccount-navigation {
  margin: 0 0 var(--padding-lg);
}
.woocommerce-MyAccount-navigation ul {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-md) var(--padding-lg);
}
.woocommerce-MyAccount-navigation a {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  text-decoration: none;
  line-height: 1;
}
.woocommerce-MyAccount-navigation a:hover {
  color: var(--color-midnight-black);
  text-decoration: underline;
}

.woocommerce-Addresses {
  display: grid;
  gap: var(--padding-lg);
}

.woocommerce-MyAccount-orders.shop_table th, .woocommerce-MyAccount-orders.shop_table td {
  padding: 0 var(--padding-sm) var(--padding-sm) 0;
}
.woocommerce-MyAccount-orders.shop_table th {
  font-weight: var(--font-weight-bold);
}
.woocommerce-MyAccount-orders.shop_table a {
  color: inherit;
}

.woocommerce-info a {
  color: inherit;
}

.woocommerce-MyAccount-content {
  display: grid;
  gap: var(--padding-lg);
}

.woocommerce-Address {
  display: grid;
  gap: var(--padding-md);
}
.woocommerce-Address address {
  font-style: normal;
}

.woocommerce-privacy-policy-text a {
  color: inherit;
}
.woocommerce-privacy-policy-text a:hover {
  opacity: 0.5;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
  padding: calc(var(--paint-daub-max-height) + var(--padding-lg)) 0 0;
  text-align: center;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white-fade-80);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;
}
.popup-overlay.is-visible {
  visibility: visible;
  opacity: 1;
}
.popup-overlay.is-visible .popup-modal {
  transform: translateY(-1rem);
}

.popup-modal {
  position: relative;
  width: min(80%, 30rem);
  background-color: var(--color-midnight-black);
  transition: all 0.5s;
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: grid;
  place-items: center;
  width: var(--btn-height);
  height: var(--btn-height);
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
.popup-close:hover {
  background-color: white;
}
.popup-close svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--color-dark-peak);
}

.popup-modal__img-outer {
  position: relative;
  z-index: 1;
}
.popup-modal__img-outer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(16, 29, 46) 0%, rgba(16, 29, 46, 0) 100%);
  opacity: 1;
}

.popup-modal__img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
}

.popup-modal__content {
  position: relative;
  z-index: 1;
  display: grid;
  justify-items: center;
  gap: var(--padding-md);
  padding: 0 var(--padding-md) var(--padding-lg);
  text-align: center;
}

.popup-modal__logo {
  width: 33.333%;
}
.popup-modal__logo svg {
  width: 100%;
  fill: white;
}

.popup-no {
  margin: 0.625rem 0 0;
  font-family: var(--loos-extended);
  font-size: 0.75rem;
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: white;
  line-height: 1;
  opacity: 0.5;
  background: none;
}
.popup-no:hover {
  text-decoration: underline;
  opacity: 1;
}

.popup-modal__terms-link {
  padding: var(--padding-md) 0 0;
  font-size: 0.75rem;
  color: white;
  text-decoration: none;
}
.popup-modal__terms-link:hover {
  text-decoration: underline;
}

.hero-form {
  position: relative;
  background-color: black;
  overflow: hidden;
}

.hero-form__bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
}
.hero-form__bg:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.95;
}

.hero-form__bg__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
  object-position: top center;
  opacity: 0.6;
}

.hero-form__form {
  position: relative;
  z-index: 3;
}

.hero-form__title {
  font-family: var(--loos-extended);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  text-align: center;
  color: white;
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);
}
.hero-form__title i {
  font-family: var(--loos-compressed);
  font-style: normal;
}

.contact-form-outer {
  position: relative;
  padding: var(--padding-lg) 0 0;
  margin: 0 0 var(--padding-lg);
  background-color: var(--color-light-peak);
}
.contact-form-outer > .inner {
  position: relative;
  z-index: 2;
}

.contact-form__text {
  display: grid;
  gap: var(--padding-lg);
}

.contact-form__compass {
  display: none;
}
@media (min-width: 768px) {
  .contact-form__compass {
    display: block;
    width: auto;
    height: 12.5rem;
    transform: rotate(10deg);
  }
}

.contact-form {
  display: grid;
  gap: var(--padding-xl);
  align-items: start;
  padding: var(--padding-lg) 0;
}
@media (min-width: 768px) {
  .contact-form {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .contact-form {
    grid-template-columns: 1fr 1.5fr;
    gap: var(--padding-xxl);
  }
}

.contact-form__row + .contact-form__row {
  margin: var(--padding-md) 0 0;
}

.contact-form__row {
  display: grid;
  gap: var(--padding-sm);
}
.contact-form__row input[type=text],
.contact-form__row input[type=password],
.contact-form__row input[type=email],
.contact-form__row input[type=url],
.contact-form__row input[type=date],
.contact-form__row input[type=month],
.contact-form__row input[type=time],
.contact-form__row input[type=datetime],
.contact-form__row input[type=datetime-local],
.contact-form__row input[type=week],
.contact-form__row input[type=number],
.contact-form__row input[type=search],
.contact-form__row input[type=tel],
.contact-form__row input[type=color],
.contact-form__row select {
  width: 100%;
  height: 3.75rem;
}
.contact-form__row select {
  padding: 0 var(--padding-xs);
  border: 1px solid var(--color-dark-peak);
  font-family: var(--loos-normal);
  font-size: var(--font-size-xs);
}
.contact-form__row select:focus {
  outline: none;
  border: 3px solid var(--color-blue-john);
}
.contact-form__row textarea {
  width: 100%;
  height: 9.375rem;
  padding: var(--padding-xs);
  border: 1px solid var(--color-dark-peak);
  font-family: var(--loos-normal);
  font-size: var(--font-size-xs);
}
.contact-form__row textarea:focus {
  outline: none;
  border: 3px solid var(--color-blue-john);
}
.contact-form__row > label {
  font-family: var(--loos-condensed);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);
}

.wpcf7-acceptance,
.wpcf7-checkbox {
  display: block;
  width: 100%;
  padding: var(--padding-xs);
}

.wpcf7-acceptance input,
.wpcf7-checkbox input {
  margin: 0 1rem 0 0;
}

.wpcf7-acceptance .wpcf7-list-item-label {
  line-height: var(--line-height-md);
}
.wpcf7-acceptance .wpcf7-list-item-label a {
  color: inherit;
}

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid-tip {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  margin: 0;
  font-weight: var(--font-weight-bold);
  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.wpcf7-response-output {
  display: block;
  width: 100%;
  padding: 1rem;
  font-weight: var(--font-weight-bold);
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;
  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: var(--padding-lg) 0 0;
  border: 1px solid #80A06E;
  background-color: #EDF1EA;
}