.product-feed {
  display: grid;
  gap: var(--padding-lg);

    @include mq(screen-xxs) {
      grid-template-columns: repeat(2, 1fr);
    }

}

.product-feed__item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-feed__item:hover {

  .title {
    text-decoration: underline;
  }
 
  .product-feed__img {
    transform: scale(1.05);
    transition: transform 0.25s ease-out;
  }

  .btn--dark-peak {
    background-color: var(--color-dark-peak-hover);
  }
  
}

.product-feed__flags {
  position: absolute;
  top: 0;
  right: pxRem(20);
  z-index: 2;

  display: grid;
  gap: 2px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

}

.product-feed__sale {
  position: absolute;
  top: 0;
  left: pxRem(20);
  z-index: 2;

  display: grid;
  gap: 2px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

}

.product-feed {
  
  .product-feed__flags,
  .product-feed__sale {
    width: pxRem(60);
  }

}

.product-hero,
.collab-product-img {

  .product-feed__flags {
    width: pxRem(80);
  }
  
}

.product-feed__img-outer {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: pxRem(250);
}

.product-feed__img {
  position: relative;
  z-index: 1;
  display: block;
  height: auto;
  transition: transform 0.25s ease-in;
}

.page-template-tpl-shop .product-feed__img {
  width: 8.5rem;
}

.page-template-tpl-merch .product-feed__img {
  width: 10rem;
}

.product-feed__text {
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 0 calc(var(--btn-height) + var(--padding-md));

    .btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

}

.product-feed__info {
  flex: 1;
  position: relative;
  display: grid;
  align-content: space-between;
  margin: 0 0 var(--padding-md);
  padding: 0 0 var(--padding-md);

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      width: 40%;
      height: 1px;
      background-color: var(--color-grey);
    }

}

.title + .product-feed__details {
  margin: pxRem(8) 0 0;
}

.product-feed__detail {
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-xxs);
  line-height: 1;
  text-transform: uppercase;
}

.product-feed__characteristics {
  font-family: var(--gravesend-sans);
  font-size: var(--font-size-xxs);
  line-height: 1;
  text-transform: uppercase;
}

.product-feed__details,
.product-feed__characteristics {
  opacity: 0.75;
}

.product-feed__price {
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 0.25rem;

    span {
      display: block;
      font-family: var(--loos-compressed);
      letter-spacing: var(--loos-kerning);
      text-transform: uppercase;
    }

    .previous-price {
      font-size: 75%;
      color: var(--color-grey);
      text-decoration: line-through;
    }

    .sale-price {
      color: var(--color-error);
    }

    .normal-price {
      color: var(--color-dark-peak);
    }

}

// Variants

.similar-beers .product-feed,
.shop .product-feed {

  @include mq(screen-xxs) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(screen-md) {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--padding-xxl) var(--padding-lg);
  }

}

.similar-beers .product-feed {

}


