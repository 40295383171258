// =================================================
// LAYOUT STYLES
// =================================================

.site {
  position: relative;
}

.site-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      
      width: 100%;
      height: 50%;

      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

}

.site-bg__img {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  opacity: 0.5;
}

.content {
  position: relative;
  z-index: 2;
}

.main {
  overflow: hidden;
}

.outer {
  position: relative;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--padding-lg);
}

.inner--sm {
  max-width: var(--mq-sm);
}

.inner--md {
  max-width: var(--mq-md);
}

.inner--lg {
  max-width: var(--mq-lg);
}

.inner--xl {
  max-width: var(--mq-xl);
}

.inner--xxl {
  max-width: var(--mq-xxl);
}