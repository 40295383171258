.promo-text {
  padding: var(--padding-lg) 0;

    p {
      font-family: var(--gravesend-sans);
      font-size: var(--font-size-md);
      text-transform: uppercase;

        @include mq(screen-sm) {
          text-align: center;
        }

    }


}

.promo-text.bg-dark-peak {
  background-color: var(--color-dark-peak);

    p {
      color: white;
    }

}