.wp-block-woocommerce-checkout,
.wp-block-woocommerce-cart {
  position: relative;
  z-index: 2;
}

div.woocommerce {
  padding: var(--paint-daub-max-height) 0 0;
}

.text-hero + div.woocommerce {
  padding: 0;
}

.woocommerce-notices-wrapper {
  margin: 0 0 var(--padding-lg);
}

.woocommerce-message,
.woocommerce-error li {
  font-weight: var(--font-weight-bold);

    a {
      color: inherit;
    }

    a:hover {
      opacity: 0.5;
    }

}

.single-product {

  .woocommerce-notices-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;

    width: 100%;
    padding: 0 var(--padding-md);

      @include mq(screen-sm) {
        position: absolute;
        top: var(--paint-daub-max-height);
        left: 50%;
        transform: translate(-50%, 0);
      }

      @include mq(screen-lg) {
        width: var(--mq-lg);
      }

  }

}

.woocommerce-message {
  padding: var(--padding-md);
  border: 1px solid #80A06E;
  background-color: #EDF1EA;
}

.woocommerce-error {
  padding: var(--padding-md);
  border: 1px solid #D35031;
  background-color: #F9E6E2;
}

.woocommerce-checkout {

  h2 {
    @extend .title;
    @extend .title--md;
  }

  .wc-block-checkout__form {

  }

  .wp-block-woocommerce-checkout-order-summary-block {
    padding: var(--padding-lg);
    background-color: var(--color-light-peak);
  }

  .wc-block-components-totals-coupon {

    a {
      color: var(--color-dark-peak);
    }

  }

  .wc-block-formatted-money-amount {
    font-family: var(--loos-compressed);
    letter-spacing: var(--loos-kerning);
    color: var(--color-dark-peak);
  }

  .wc-block-components-order-summary__button-text,
  .wc-block-components-totals-item__label {
    font-weight: var(--font-weight-bold);
  }

  .wc-block-components-totals-footer-item .wc-block-components-totals-item__label {
    font-weight: normal;
    @extend .title;
    @extend .title--md;
  }

}

// Checkout specific

.woocommerce-table--order-details {
  width: 100%;
}

.wc-block-components-form .wc-block-components-text-input input[type=email], .wc-block-components-form .wc-block-components-text-input input[type=number], .wc-block-components-form .wc-block-components-text-input input[type=tel], .wc-block-components-form .wc-block-components-text-input input[type=text], .wc-block-components-form .wc-block-components-text-input input[type=url], .wc-block-components-text-input input[type=email], .wc-block-components-text-input input[type=number], .wc-block-components-text-input input[type=tel], .wc-block-components-text-input input[type=text], .wc-block-components-text-input input[type=url], .wc-block-components-country-input input[type=text] {
  height: pxRem(60);

  border-radius: 0 !important;
  -webkit-appearance: none;

    &:focus {
      outline: none;
      border: 3px solid var(--color-blue-john) !important;
    }

}

.wc-block-components-checkout-place-order-button {
  display: inline-block !important;
  height: var(--btn-height) !important;
  padding: 0 var(--padding-sm) !important;

  font-family: var(--loos-extended) !important;
  font-size: var(--font-size-xxs) !important;
  line-height: var(--btn-height) !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  text-align: center !important;

  border: none !important;
  cursor: pointer !important;

  @extend .btn--dark-peak;
}

.wc-block-components-checkout-return-to-cart-button {
  padding: 0 !important;
  text-decoration: underline !important;

  @extend .text-btn;

    svg {
      display: none !important;
    }
}

.order-review {
  display: grid;
  gap: var(--padding-lg);

    .woocommerce-order-overview strong {
      font-weight: var(--font-weight-bold);
    }

    .woocommerce-order-details {
      display: grid;
      gap: var(--padding-lg);
      padding: var(--padding-lg) 0 0;

      border-top: 1px solid var(--color-grey-light);
    }

    .shop_table {
      padding: var(--padding-md);

      background-color: var(--color-light-peak);
    }

    .shop_table td, th {
      padding: 0 0 var(--padding-sm) !important;
    }

    .shop_table th {
      font-weight: var(--font-weight-bold);
    }

    .woocommerce-Price-amount {
      font-family: var(--loos-compressed);
      letter-spacing: var(--loos-kerning);
      color: var(--color-dark-peak);
    }

}

.variation {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 0;
  margin: var(--padding-xs) 0 0;
  font-size: 12px;
}

.variation dd,
.variation dt {
  margin: 0;
  padding: 0;
}