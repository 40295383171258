.hero-form {
  position: relative;
  background-color: black;

  overflow: hidden;
}

.hero-form__bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;

    &:after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      
      width: 100%;
      height: 100%;

      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      opacity: 0.95;
    }

}

.hero-form__bg__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  display: block;
  width: 100%;
  height: auto;
  object-position: top center;

  opacity: 0.6;
}

.hero-form__form {
  position: relative;
  z-index: 3;
}

.hero-form__title {
  font-family: var(--loos-extended);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  text-align: center;
  color: white;
  text-transform: uppercase;
  line-height: var(--loos-line-height);
  letter-spacing: var(--loos-kerning);

    i {
      font-family: var(--loos-compressed);
      font-style: normal;
    }

}