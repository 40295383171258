.pre-header {
  display: flex;
  justify-content: center;
  gap: var(--padding-xl);
  padding: pxRem(5) var(--padding-md);

  background-color: var(--color-cavern-blue);
}

.pre-header__cta {
  display: flex;
  align-items: center;
  gap: pxRem(10);
  font-family: var(--loos-compressed);
  font-size: var(--font-size-xxs);
  text-transform: uppercase;
  color: white;
  text-align: center;
  text-decoration: none;

    &:hover {
      text-decoration: underline;

        svg {
          opacity: 1;
        }

    }

    @include mq(screen-md) {
      font-family: var(--gravesend-sans);
    }

    svg {
      width: pxRem(15);
      height: pxRem(15);
      
      fill: currentColor;
      opacity: 0.25;
    }

}

.pre-header__cta:last-child {
  display: none;

    @include mq(screen-md) {
      display: flex;
    }

}

.pg-header {
  position: relative;
  z-index: 5000;
  display: grid;
  place-items: center;
  padding: var(--padding-md) pxRem(5) var(--padding-xs) var(--padding-md);

  background-color: var(--color-light-peak);

    @include mq(screen-sm) {
      // justify-content: center;
      padding: var(--padding-lg) var(--padding-md) var(--padding-xs);
    }

    @include mq(screen-xl) {
      padding: var(--padding-lg) var(--padding-xl) var(--padding-xs);
    }

}

.woocommerce-cart .pg-header {
  z-index: 2;
}

.paint-daub {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;

  width: 100%;

    @include mq(screen-xxl) {
      width: var(--mq-xxl);
      height: var(--paint-daub-max-height);
    }

    svg {
      width: 100%;
      height: auto;

      fill: var(--color-light-peak);
    }

}

// Hide navigation on mobile

.main-nav--left {
  display: none;

    @include mq(screen-md) {
      display: block;
      position: absolute;
      top: 50%;
      left: var(--padding-lg);
      transform: translateY(-50%);
      z-index: 1;
    }

    @include mq(screen-xl) {
      left: var(--padding-xl);
    }

}

.main-nav--right {
  // display: flex;
  
    @include mq(screen-md) {
      position: absolute;
      top: 50%;
      right: var(--padding-lg);
      transform: translateY(-50%);
      z-index: 1;

      display: flex;
      align-items: center;
      gap: var(--padding-xl);
    }

    @include mq(screen-xl) {
      right: var(--padding-xl);
    }

}

.main-nav--right .main-nav__list {
  display: none;
}

// Turn it back on for desktop

.main-nav--left,
.main-nav--right {

  .main-nav__list {
    
    @include mq(screen-md) {
      display: flex;
      gap: var(--padding-xl);
    }

  }

  .main-nav__list li {
    line-height: 1;
  }

}

.main-nav__list a {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xs);
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  text-decoration: none;
  line-height: 1;

    &:hover {
      color: var(--color-midnight-black);
      text-decoration: underline;
    }

}

.pg-header__logo {
  display: block;
  width: pxRem(120);
  margin: pxRem(10) 0 0;

  line-height: 1;

    svg {
      fill: var(--color-dark-peak);
    }

    @include mq(screen-sm) {
      width: pxRem(150);
      margin: 0;
    }

}

.mobile-nav {
  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10000;

  width: 100%;
  padding: var(--padding-lg) var(--padding-md) var(--padding-xl);
  box-shadow: 0px 8px 8px rgba(0, 0, 0,0.25);

  background-color: var(--color-light-peak);

    &.is-open {
      display: block;

        @include mq(screen-md) {
          display: none;
        }

    }

    .main-nav__list {
      display: grid;
      gap: var(--padding-lg);
    }

    a {
      display: block;
      width: 100%;
      text-align: center;
    }

    @include mq(screen-sm) {
      display: none;
    }
    
}

.toggle-mobile-nav {
  position: absolute;
  top: calc(50% - pxRem(20));
  left: var(--padding-md);
  z-index: 1;

  display: grid;
  place-items: center;

  width: pxRem(40);
  height: pxRem(40);
  padding: pxRem(10);

  background: none;

    svg {
      fill: var(--color-dark-peak);
    }

    @include mq(screen-md) {
      display: none;
    }

}

.toggle-mobile-nav.is-active {
  background-color: var(--color-dark-peak);

    svg {
      fill: white;
    }

}

.pg-header__actions {
  display: flex;
  position: absolute;
  top: calc(50% - pxRem(20));
  right: 0;
  z-index: 1;
  gap: var(--padding-xs);

    @include mq(screen-md) {
      position: relative;
      top: auto;
      gap: var(--padding-md);
    }
}

.pg-header__basket {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-xxs);
  // width: pxRem(40);
  height: pxRem(40);
  padding: 0 pxRem(5);

  font-family: var(--loos-compressed);
  font-size: var(--font-size-xxs);
  text-decoration: none;
  color: var(--color-dark-peak);

    @include mq(screen-sm) {
      padding: 0 pxRem(10);
    }

    @include mq(screen-md) {
      right: 0;
    }

    svg {
      width: pxRem(20);
      height: pxRem(20);
      fill: currentColor;
    }

    &:hover {
      background-color: var(--color-dark-peak);

      color: white;
    }

}

.pg-header__basket__label {
  display: none;
}

// .pg-header__basket__count {
//   display: 
// }

// Dark variant for collab page

.dark-theme {

  .main-nav__list a,
  .pg-header__basket {
    color: var(--color-light-peak);

      &:hover {
        color: white;
      }
      
  }

  .pg-header__logo {

    svg {
      fill: var(--color-light-peak);
    }

  }

  .toggle-mobile-nav {
    display: grid;
    place-items: center;
  
    width: pxRem(40);
    height: pxRem(40);
    padding: pxRem(10);
  
    background: none;
  
      svg {
        fill: var(--color-light-peak)
      }
  
      @include mq(screen-sm) {
        display: none;
      }
  
  }
  
  .toggle-mobile-nav.is-active {
    background-color: var(--color-light-peak);
  
      svg {
        fill: black;
      }
  
  }

}

.dark-theme,
.light-theme {

  .pg-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    
    background: none;
  }

}